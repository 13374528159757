import {
  Field,
  FieldArrayFieldsProps,
  WrappedFieldArrayProps,
} from "redux-form";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { Sample } from "./testRequestTypes";
import AddSamplesButton from "./samples/AddSamplesButton";
import BasicSelectInput from "../form/BasicSelectInput";
import RenderField from "../utils/renderField";
import material_type from "../../data/sample/material_type";
import {
  Button,
  Popover,
  PopoverBody,
  PopoverHeader,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import useModal from "../hooks/useModal";
import isSubmitting from "../utils/submitting";
import SelectInput from "../form/SelectInput";
import deleteSwal from "../utils/deleteSwal";
import { useWindowEvent } from "../hooks/useWindowEvent";
import renderToggleInput from "../utils/renderToggleInput";
import { BsBucket } from "react-icons/bs";

const SampleFields = ({
  fields,
  handleSubmit,
  change,
}: WrappedFieldArrayProps<Sample> & {
  handleSubmit: Function;
  change: Function;
}) => {
  const { takeAction, loading }: IUseApi<{}, { data: Sample[] }> = useApi();
  const { uuid } = useParams<{ uuid: string }>();
  const { data: staff } = useApi("custom-fields/users", []);

  const {
    data: subMethods,
  }: IUseApiWithData<
    { value: number; label: string; test_method_id: number }[]
  > = useApi("sampleregisters/submethods", []);

  useWindowEvent("keydown", (event: KeyboardEvent) => {
    const id = document.activeElement?.id ?? "";
    let match = id.match(/\d+/);
    let number = match ? Number(match[0]) : null;
    const name = id.split(".")?.[1];

    if (event.altKey && event.key === "ArrowLeft") {
      if (number && number > 0) {
        number--;
        document.getElementById(`samples[${number}].${name}`)?.focus();
      }
    }
    if (event.altKey && event.key === "ArrowRight") {
      if (number !== null && number < fields.length - 1) {
        number++;
        console.log(`samples[${number}].${name}`);
        document.getElementById(`samples[${number}].${name}`)?.focus();
      }
    }
  });

  const { data: methods } = useApi("sampleregisters/methods", []);

  // Add state for request queue
  const [saveQueue, setSaveQueue] = useState<(() => Promise<any>)[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  // Process queue when it changes
  useEffect(() => {
    const processQueue = async () => {
      if (saveQueue.length === 0 || isProcessing) return;

      setIsProcessing(true);

      try {
        const nextRequest = saveQueue[0];
        await nextRequest();
        setSaveQueue((prev) => prev.slice(1));
      } catch (error) {
        errorSwal(error);
      }
      setIsProcessing(false);
    };

    processQueue();
  }, [saveQueue, isProcessing]);

  const saveSample = (index: number) => {
    return handleSubmit((values: { samples: Sample[] }) => {
      const sample = values.samples[index];

      // Create save function and add to queue
      const saveFunction = () =>
        takeAction("update", `sampleregisters/${sample.uuid}`, sample);
      setSaveQueue((prev) => [...prev, saveFunction]);
    });
  };

  const addSamples = (amount: number) => {
    return takeAction("store", `test-requests/${uuid}/samples`, {
      sampled_at: dayjs().format("YYYY-MM-DD"),
      amount,
    })
      .then(({ data }) => {
        data.data.forEach((sample) => {
          fields.push(sample);
        });
        toast.success("Samples Added");
      })
      .catch(errorSwal);
  };

  const deleteSample = (sample: Sample) => {
    return deleteSwal(`${sample.prefix}/${sample.number}`)
      .then(() => takeAction("destroy", `sampleregisters/${sample.uuid}`))
      .then(() => {
        toast.success("Sample Deleted");
        fields.remove(fields.getAll().indexOf(sample));
      })
      .catch(errorSwal);
  };

  return (
    <div className="d-block">
      <div
        style={{
          backgroundColor: "var(--bs-body-bg)",
        }}
        className="position-sticky top-0 left-0 z-10"
      >
        {fields.length > 0 && (
          <p className="text-dark tx-16 fw-bolder">
            {fields.length} samples{" "}
            {isProcessing && (
              <Spinner className="text-secondary ms-2" size="sm" />
            )}
          </p>
        )}
      </div>
      <div className="d-flex row position-relative flex-nowrap w-100 space-x-3">
        {fields.map((fieldName, index) => {
          const sample = fields.get(index);

          const sampleNumber = `${sample.prefix}/${sample.number}`;

          return (
            <div
              key={index}
              className="position-relative overflow-col-lg-4 overflow-col-md-6 overflow-col-12"
            >
              <div
                style={{
                  backgroundColor: "var(--bs-body-bg)",
                }}
                className="top-0 position-sticky z-10 "
              >
                <div className="d-flex align-items-center mb-3">
                  <p className="mb-0 text-dark fw-bolder">{sampleNumber}</p>
                  <CopySampleButton fields={fields} sample={sample} />
                </div>
                <Button
                  disabled={index === 0}
                  onClick={() => {
                    const {
                      location_1,
                      location_2,
                      location_3,
                      location_4,
                      sampled_at_time,
                      sampled_at_date,
                      uuid,
                      id,
                      number,
                      ...data
                    } = fields.get(index - 1);

                    Object.entries(data).forEach(([key, value]) => {
                      change(`${fieldName}.${key}`, value);
                    });

                    takeAction(
                      "update",
                      `sampleregisters/${sample.uuid}`,
                      data,
                    );
                  }}
                  size="sm"
                  className="px-0 mb-3"
                  color="link"
                >
                  Copy Data from previous sample
                </Button>
              </div>
              <Field
                component={BasicSelectInput}
                wrapperClass="form-group"
                name={`${fieldName}.method`}
                onBlur={saveSample(index)}
                label="Method"
                options={[{}, ...methods]}
              />
              <Field
                component={BasicSelectInput}
                wrapperClass="form-group"
                name={`${fieldName}.sub_method`}
                label="Sub Method"
                onBlur={saveSample(index)}
                options={[
                  {},
                  ...subMethods.filter(
                    (method) => method.test_method_id == sample.method,
                  ),
                ]}
              />
              <div className="form-group">
                <Field
                  component={RenderField}
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  name={`${fieldName}.lot_number`}
                  label="Lot Number"
                />
              </div>

              <div className="form-group">
                <Field
                  component={RenderField}
                  name={`${fieldName}.material_type`}
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  type="text"
                  label="Material Type"
                  list={`material_type`}
                />
                <datalist id={`material_type`}>
                  {material_type.map((item) => (
                    <option key={item.value} value={item.value} />
                  ))}
                </datalist>
              </div>
              <div className="form-group">
                <Field
                  label="Material Colour"
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  name={`${fieldName}.material_colour`}
                  component={RenderField}
                />
              </div>
              <div className="form-group">
                <Field
                  label="Material Description"
                  name={`${fieldName}.material_description`}
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  component={RenderField}
                />
              </div>
              <div className="form-group">
                <Field
                  label="Material Source"
                  name={`${fieldName}.material_source`}
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  component={RenderField}
                />
              </div>
              <div className="form-group">
                <Field
                  label="For Use As"
                  name={`${fieldName}.for_use_as`}
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  component={RenderField}
                />
              </div>
              <div className="form-group">
                <Field
                  label="Location 1"
                  name={`${fieldName}.location_1`}
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  component={RenderField}
                />
              </div>
              <div className="form-group">
                <Field
                  label="Location 2"
                  name={`${fieldName}.location_2`}
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  component={RenderField}
                />
              </div>
              <div className="form-group">
                <Field
                  label="Location 3"
                  name={`${fieldName}.location_3`}
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  component={RenderField}
                />
              </div>
              <div className="form-group">
                <Field
                  label="Location 4"
                  name={`${fieldName}.location_4`}
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  component={RenderField}
                />
              </div>
              <div className="form-group">
                <Field
                  label="Sampled By Client"
                  onChange={(e: any, value: boolean) => {
                    if (value) {
                      change(`${fieldName}.user_id`, null);
                    }

                    change(`${fieldName}.sampled_by_client`, value);
                    return setTimeout(saveSample(index), 0);
                  }}
                  name={`${fieldName}.sampled_by_client`}
                  component={renderToggleInput}
                />
              </div>
              {!sample.sampled_by_client && (
                <div className="form-group">
                  <Field
                    onBlur={saveSample(index)}
                    label="Sampled By"
                    name={`${fieldName}.user_id`}
                    component={SelectInput}
                    /** @ts-ignore */
                    options={
                      staff?.map((d: any) => ({
                        label: d.name,
                        value: d.id,
                        disabled: d.disabled,
                      })) ?? []
                    }
                  />
                </div>
              )}
              <div className="form-group">
                <Field
                  label="Sampled Date"
                  name={`${fieldName}.sampled_at_date`}
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  type="date"
                  component={RenderField}
                />
              </div>
              <div className="form-group">
                <Field
                  label="Sampled Time"
                  name={`${fieldName}.sampled_at_time`}
                  type="time"
                  extraProps={{
                    onBlur: saveSample(index),
                  }}
                  component={RenderField}
                />
              </div>
              <div className="form-group">
                <Button
                  onClick={() => deleteSample(sample)}
                  block
                  outline
                  color="danger"
                  type="button"
                >
                  Delete {sample.prefix}/{sample.number}
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      {fields.length === 0 ? (
        <div className="d-flex justify-content-center mt-5">
          <div>
            <p className="text-center">
              <BsBucket className="text-center tx-32" />
            </p>
            <p className="text-center text-dark tx-16 mb-1">No Samples</p>
            <p className="text-center">
              Get started by adding your first samples.
            </p>
            <div className="d-flex justify-content-center">
              <AddSamplesButton
                addSamples={addSamples}
                size="sm"
                loading={loading}
              />
            </div>
          </div>
        </div>
      ) : (
        <AddSamplesButton addSamples={addSamples} loading={loading} />
      )}
    </div>
  );
};

const CopySampleButton = ({
  fields,
  sample,
}: {
  fields: FieldArrayFieldsProps<Sample>;
  sample: Sample;
}) => {
  const [amount, setAmount] = useState(1);

  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction, loading }: IUseApi<{}, { data: Sample[] }> = useApi();

  const copySample = (amount: number) => {
    return takeAction("store", `test-requests/${uuid}/samples`, {
      ...sample,
      amount,
    })
      .then(({ data }) => {
        data.data.forEach((sample) => {
          fields.push(sample);
        });
        toast.success("Samples Added");
      })
      .catch(errorSwal);
  };

  const { toggle, modal } = useModal();

  return (
    <>
      <Button
        size="sm"
        color="primary"
        id={`copy-${sample.uuid}`}
        type="button"
        outline
        className="ms-auto"
      >
        Copy Sample
      </Button>
      <Popover
        target={`copy-${sample.uuid}`}
        placement="top"
        isOpen={modal}
        toggle={toggle}
      >
        <PopoverHeader>
          Copy {sample.prefix}/{sample.number}
        </PopoverHeader>
        <PopoverBody className="p-3">
          <label htmlFor="amount" className="fw-bolder tx-inverse">
            Number of Samples.
          </label>
          <input
            id="amount"
            className="form-control"
            type="number"
            value={amount}
            onChange={(e: any) => setAmount(e.target.value)}
          />
          <div className="d-flex">
            <Button
              disable={loading}
              className="mt-3 ms-auto"
              size="sm"
              onClick={() => copySample(amount).then(toggle)}
            >
              {isSubmitting(loading, "Add", "Adding...")}
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default SampleFields;
