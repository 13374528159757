import { IoInformationCircleOutline } from "react-icons/io5";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import LoadingOverlay from "../utils/LoadingOverlay";
import { User } from "./userTypes";
import BottomLogo from "../utils/BottomLogo";

const ChangeLabourHireOrganisation = ({
  user,
  setUser,
}: {
  user: User["user"];
  setUser: (user: User["user"]) => void;
}) => {
  const { takeAction, loading }: IUseApi = useApi();

  if (!user.child_labour_hire_organisations) {
    return null;
  }

  const currentOrganisationUserCount =
    user.child_labour_hire_organisations.filter(
      (organisation) => !organisation.employment_ended,
    ).length;

  return (
    <div className="my-3 bg-white rounded-lg shadow-sm p-3 position-relative">
      <BottomLogo size={20} bottom={15} left={250} />
      <LoadingOverlay loading={loading} />
      <div className="d-flex align-items-center ">
        <IoInformationCircleOutline className="text-dark me-2 tx-30" />
        <div>
          <p className="mb-2 text-dark">
            You are currently viewing data from the organisation{" "}
            <select
              title="Active Organisation"
              id="organisation-select"
              className="border-0"
              value={user.organisation_user?.organisation_id}
              onChange={(e) => {
                takeAction(
                  "show",
                  `/users/${user.uuid}?child_labour_hire_organisation_id=${e.target.value}`,
                ).then(({ data }) => {
                  setUser({ ...data.data.user });
                });
              }}
            >
              {user.child_labour_hire_organisations.map((option) => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.name} {option.employment_ended ? "(Ended)" : ""}
                  </option>
                );
              })}
            </select>
            .
          </p>
          <p className="mb-0 tx-gray-500">
            {user.first_name} currently has access to{" "}
            {currentOrganisationUserCount} organisation
            {currentOrganisationUserCount == 1 ? "" : "s"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChangeLabourHireOrganisation;
