import { Link } from "react-router-dom";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import InformationAlert from "../utils/InformationAlert";
import renderField from "../utils/renderField";
import isSubmitting from "../utils/submitting";
import BottomLogo from "../utils/BottomLogo";

const ForgotPassword = (props: InjectedFormProps) => {
  const { handleSubmit, submitting, submitSucceeded, error, reset } = props;

  const { takeAction }: IUseApi = useApi();

  const onSubmit = (values: any) => {
    return takeAction("store", "auth/forgot", values)
      .then(reset)
      .catch(formError);
  };

  return (
    <div
      className="signin-wrapper signin-brick position-absolute"
      style={{ height: "100vh", width: "100vw" }}
    >
      <BottomLogo />
      <div className="signin-box signup rounded-lg shadow-sm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="slim-logo">
            <a href="/">
              Boss<span>.</span>
            </a>
          </h2>
          <h3 className="signin-title-primary">Reset password!</h3>
          <h5 className="signin-title-secondary lh-4">
            {submitSucceeded
              ? "Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder."
              : "Enter your email address and we will send you a link to reset your password."}
          </h5>

          {error && (
            <div className="mb-3">
              <InformationAlert
                type="danger"
                closable
                body={error}
                title="Error when Submitting Form"
              />
            </div>
          )}

          {!submitSucceeded && (
            <div>
              <div className="form-group">
                <Field
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Email address"
                  component={renderField}
                />
              </div>
              <button
                className="btn btn-primary w-100 btn-signin"
                disabled={submitting}
              >
                {isSubmitting(
                  submitting,
                  "Reset my password",
                  "Sending Email...",
                )}
              </button>
            </div>
          )}

          <p className="mg-t-40 mg-b-0">
            Already have an account? <Link to="/login">Sign In</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

const register = reduxForm({ form: "Register", touchOnBlur: true });
export default register(ForgotPassword);
