import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import MentionDisplay from "../form/MentionDisplay";
import CustomScaleLoader from "../utils/scaleLoader";
import CommentForm from "./CommentForm";

const NoticeComments = ({ notice, show, toggle }) => {
  const {
    data: comments,
    setUrl,
    setData: setComments,
    loading,
    response,
  } = useApi();

  const [commentUrl, setCommentUrl] = useState(
    `comments?filter[commentable_id]=${
      notice.id
    }&filter[commentable_type]=${encodeURIComponent(
      "App\\Models\\Noticeboard",
    )}`,
  );

  useEffect(() => {
    if (show) {
      setUrl(commentUrl);
    }
  }, [show, commentUrl]);

  if (!show) {
    return null;
  }

  if (loading) {
    return (
      <div className="my-5">
        <CustomScaleLoader>Fetching Comments...</CustomScaleLoader>
      </div>
    );
  }

  const meta = response?.data?.meta;

  return (
    <>
      <div className="space-y-5 py-3 px-4">
        {comments?.map((comment) => {
          return (
            <div key={comment.uuid}>
              <div className="mb-2">
                <div
                  className="p-3 bg-gray-100"
                  style={{
                    borderRadius: "15px",
                  }}
                >
                  <MentionDisplay content={comment.comment} />
                </div>
              </div>
              <div className="d-flex align-items-center tx-inverse">
                <span className="tx-gray-500 mx-1"> Added on</span>{" "}
                {dayjs(comment.created_at).format("DD/MM/YYYY")}{" "}
                <span className="tx-gray-500 mx-1"> at</span>{" "}
                {dayjs(comment.created_at).format("h:mm A")}{" "}
                <span className="tx-gray-500 mx-1"> | By</span>{" "}
                <a href={`mailto:${comment.author.email}`}>
                  {comment.author.name}
                </a>
              </div>
            </div>
          );
        })}
        {meta && meta.total > 0 && (
          <p className="mb-0">
            Showing comments {meta.from} to {meta.to} of {meta.total}{" "}
            {meta.to < meta.total && (
              <Button
                onClick={() =>
                  setCommentUrl(`${commentUrl}&paginate=${meta.total}`)
                }
                color="link"
                className="p-0"
              >
                View All
              </Button>
            )}
          </p>
        )}
      </div>
      <CommentForm
        form="NoticeComment"
        comments={comments}
        setComments={setComments}
        notice={notice}
      />
    </>
  );
};

export default NoticeComments;
