import TextButton from "../../utils/TextButton";
import { BsFileExcel } from "react-icons/bs";
import useModal from "../../hooks/useModal";
import FormModal from "../../utils/FormModal";
import formError from "../../utils/formError";
import useApi from "../../api/useApi";
import { Field } from "redux-form";
import { IUseApi } from "../../api/apiTypes";
import { useInfiniteHits, useSearchBox } from "react-instantsearch";
import { SampleIndex } from "./sampleTypes";
import InstantSearchSelectInput from "../../form/InstantSearchSelectInput";

const GenerateCoc = () => {
  const { toggle, modal } = useModal();

  return (
    <>
      <TextButton className="text-secondary" onClick={toggle}>
        <BsFileExcel /> Generate COC
      </TextButton>
      <CocModal toggle={toggle} modal={modal} />
    </>
  );
};

const CocModal = ({
  toggle,
  modal,
}: {
  toggle: () => void;
  modal: boolean;
}) => {
  const {
    takeAction,
  }: IUseApi<
    {},
    {
      data: {
        path: string;
      };
    }
  > = useApi();

  const { refine } = useSearchBox();
  const { items, isLastPage, showMore } = useInfiniteHits<SampleIndex>();

  const generateCoc = (values: {
    samples: { label: string; value: number }[];
  }) => {
    return takeAction("store", "generate-chain-of-custody", {
      samples: values.samples.map((sample) => sample.value),
    })
      .then(({ data }) => {
        window.open(data.data.path, "_blank");
      })
      .catch(formError);
  };

  return (
    <FormModal
      title="Generate COC"
      onSubmit={generateCoc}
      toggle={toggle}
      modal={modal}
      form="generate-coc"
    >
      <div className="col-12 form-group">
        <Field
          component={InstantSearchSelectInput}
          hits={items}
          refine={refine}
          loadMore={showMore}
          hasMore={!isLastPage}
          isMulti={true}
          label="Samples"
          name="samples"
          options={items.map((item) => ({
            label: item.number,
            value: item.id,
          }))}
        />
      </div>
    </FormModal>
  );
};

export default GenerateCoc;
