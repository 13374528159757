import React from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import SideList from "../comments/SideList";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import ApprovalButton from "../approvals/ApprovalButton";
import InfoCard from "../utils/InfoCard";
import Spinner from "../utils/Spinner";
import DeleteButton from "./DeleteButton";
import ProfileButtons from "./ProfileButtons";
import ProfileHeader from "./ProfileHeader";
import SentDeliverableList from "./SentDeliverableList";
import ShowPage from "./ShowPage";
import ProfileViewsList from "../profileViews/ProfileViewsList";
import { useAuth } from "../../context/auth-context";
import ProfileSingleFile from "../utils/ProfileSingleFile";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";

type SharedDeliverableProfilePages = "details" | "deliverable" | "documents";

const SharedDeliverablesProfile = (props : any) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { user } = useAuth();

  const isPositionAdmin = user?.is_position_admin;

  const {
    loading,
    data: deliverable,
    setData: setDeliverable,
  } = useApi(`deliverables/${uuid}`, "");

  const tabs = [
    {
      link: `/deliverables/${uuid}/deliverable`,
      icon: "fa fa-gift",
      page: "deliverable",
      label: "Deliverable",
    },
    {
      link: `/deliverables/${uuid}/details`,
      icon: "fa fa-file-text-o",
      page: "details",
      label: "Details",
    },
    {
      link: `/deliverables/${uuid}/documents`,
      icon: "fa fa-history",
      page: "documents",
      label: "Documents",
    },
  ];

  if (loading || !deliverable) return <Spinner loading />;

  const isInvoiced = deliverable?.invoice?.requested_at;

  return (
    <>
      <HeaderPage
        titlePage={deliverable.name}
        crumbs={[
          { link: "projects", name: "Projects" },
          {
            link: deliverable.project.link,
            name: deliverable.project.number,
          },
          { link: deliverable.job.link, name: deliverable.job.number },
          { link: "/", name: deliverable.name, active: true },
        ]}
      />
      
      <Profile
        header={<ProfileHeader deliverable={deliverable} />}
        content={
          <>
            <ShowSharedDeliverablesProfilePage
              {...props}
              deliverable={deliverable}
              setDeliverable={setDeliverable}
              isInvoiced={isInvoiced}
            />
          </>
        }
        tabs={tabs}
        sideBar={
          <>
            <div className="mt-3">
              <SideList
                commentableType="App\Models\Deliverable"
                commentableId={deliverable.id}
              />
            </div>
          </>
        }
      />
    </>
  );
};


const ShowSharedDeliverablesProfilePage = (props : any) => {
  const { page, uuid } = useParams<{
    uuid: string;
    page: SharedDeliverableProfilePages;
  }>();
  const { user } = useAuth();
  const { deliverable } = props;
  const { document } = deliverable.current_revision;

  switch (page) {
    case "deliverable":
      console.log(document?.inline_link);
      return (
        <ProfileSingleFile
          disableUpload
          link={document?.inline_link}
          downloadLink={document?.link}
          mime_type={document?.mime_type}
        />
      );
    case "documents":
      return (
        <>
          <p>Upload extra files to be sent to the client.</p>
          <NewProfileDocuments
            {...props}
            disableUpload
            model="App\Models\Deliverable"
            documentableId={uuid}
            documentPath={`organisations/${user?.active_organisation.uuid}/documents/deliverables/${uuid}`}
            homeUrl={`projects/${uuid}/documents`}
            noFolders
          />
        </>
      );
    case "details":
      return <DeliverableDetails deliverable={deliverable} />;
    default:
      return null;
  }
};

const DeliverableDetails = ({ deliverable }: { deliverable: any }) => {

  const DetailItem = ({
    label,
    value,
  }: {
    label: string;
    value: string | React.ReactNode;
  }) => (
    <div className="mb-3">
      <p className="text-dark mb-1 tx-12">{label}</p>
      <p className="mb-0">{value}</p>
    </div>
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <DetailItem label="Name" value={deliverable.name} />
          <DetailItem label="Is Progress Claim" value={deliverable.is_progress_claim} />
        </div>
        <div className="col-md-6">
          <DetailItem label="Line Item Prefix" value={deliverable.purchase_order} />
        </div>
      </div>

      <div className="mt-4">
        <h6 className="text-dark mb-3">Contacts</h6>
        {deliverable.contacts.length > 0 ? (
          <div className="space-y-3">
            {deliverable.contacts.map((contract: {value: number, label: string}) => (
              <div key={contract.value}>
                <p className="mb-0 text-dark">{contract.label}</p>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-muted">No contact assigned.</p>
        )}
      </div>

      <div className="mt-4">
        <h6 className="text-dark mb-3">Pricing</h6>
        <table className="table">
          <thead>
            <tr>
              <th>Item Type</th>
              <th>Units</th>
              <th>Type</th>
              <th>Price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {deliverable.pricing.map((pricing: any) => (
              <tr key={deliverable.uuid}>
                <td>{pricing.line_item_type_label}</td>
                <td>{pricing.billable_item_units_label} </td>
                <td>{pricing.line_item_label}</td>
                <td>{pricing.price}</td>
                <td>{pricing.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};


export default SharedDeliverablesProfile;
