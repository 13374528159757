import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonGroup } from "reactstrap";
import swal from "sweetalert";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import TransferTasksModal from "./TransferTasksModal";
import { useState } from "react";
import IncompleteTasksModal from "./IncompleteTasksModal";

const EmploymentButtons = ({ uuid, user, setUser }) => {
  const history = useHistory();
  const { toggle, modal } = useModal();
  const { user: authUser } = useAuth();

  const { modal: incompleteTasksModal, toggle: toggleIncompleteTasksModal } =
    useModal();

  const [incompleteTasks, setIncompleteTasks] = useState();

  const { takeAction, loading } = useApi();

  const cancelEmployment = () => {
    swal({
      title: "Cancel Employment?",
      text: "Are you sure you want to cancel employment?",
      icon: "warning",
      buttons: true,
    })
      .then((willCancel) => {
        if (!willCancel) {
          return Promise.reject();
        }

        let query = "";

        if (
          authUser.active_organisation.id !==
          user.organisation_user.organisation.id
        ) {
          query = `?labour_hire_organisation_id=${user.organisation_user.organisation.id}`;
        }

        return takeAction("destroy", `organisation-users/${uuid}${query}`);
      })
      .then(() => {
        toast.success("Employment Cancelled");
        history.push("/users");
      })
      .catch((err) => {
        if (!err) {
          return;
        }

        if (err?.response?.data?.key === "incomplete-tasks") {
          setIncompleteTasks(err.response.data.data);
          toggleIncompleteTasksModal();
          return;
        }

        return errorSwal(err);
      });
  };

  const reinstateEmployment = () => {
    swal({
      title: "Reinstate Employment Employment?",
      text: "Are you sure you want to reinstate employment?",
      icon: "warning",
      buttons: true,
    }).then((willCancel) => {
      if (willCancel) {
        let query = "";

        if (
          authUser.active_organisation.id !==
          user.organisation_user.organisation.id
        ) {
          query = `?labour_hire_organisation_id=${user.organisation_user.organisation.id}`;
        }

        return takeAction(
          "store",
          `organisation-users/${uuid}/reinstate${query}`,
        )
          .then(({ data }) => {
            toast.success("Employment Reinstated");
            setUser(data.data.user);
          })
          .catch(errorSwal);
      }
    });
  };

  if (!authUser.is_admin) {
    return null;
  }

  return (
    <>
      <ButtonGroup className="w-100 mg-t-20">
        {user?.organisation_user?.employment_ended ? (
          <Button onClick={reinstateEmployment} outline disabled={loading}>
            Reinstate Employment
          </Button>
        ) : (
          <Button onClick={cancelEmployment} outline disabled={loading}>
            Cancel Employment
          </Button>
        )}
        <Button onClick={toggle} outline>
          Transfer Tasks
        </Button>
      </ButtonGroup>
      <TransferTasksModal toggle={toggle} modal={modal} user={user} />
      <IncompleteTasksModal
        toggle={toggleIncompleteTasksModal}
        modal={incompleteTasksModal}
        tasks={incompleteTasks}
      />
    </>
  );
};

export default EmploymentButtons;
