import _ from "lodash";
import { BiCheck } from "react-icons/bi";

interface Option {
  value: string;
  label: string;
  description?: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface ButtonProps extends Option {
  active: boolean;
  onClick: () => void;
}

interface StyledCheckBoxButtonProps {
  input: {
    value: string[];
    onChange: (value: string[]) => void;
  };
  options: Option[];
  button: (props: ButtonProps) => React.ReactNode;
}

const StyledCheckBoxButton = (props: StyledCheckBoxButtonProps) => {
  const {
    options,
    button,
    input: { value, onChange },
  } = props;

  return (
    <div className="d-flex flex-wrap gap-2">
      {options.map((option) => {
        const isActive = value?.includes(option.value);

        return (
          <button
            key={option.value}
            type="button"
            className={`btn position-relative px-4 py-1 bg-white ${
              isActive
                ? "border-warning shadow-none"
                : "border border-gray-200 shadow-sm"
            } rounded-lg duration-300 d-flex align-items-center`}
            style={{
              minWidth: "100px",
              borderWidth: isActive ? "1.5px" : "1px",
            }}
            onClick={() => onChange(_.xor(value ?? [], [option.value]))}
          >
            <div
              style={{ right: -1, top: -1 }}
              className={`position-absolute ${
                isActive ? "opacity-100" : "opacity-0"
              }`}
            >
              <BiCheck className="tx-warning" style={{ fontSize: "18px" }} />
            </div>
            <div className="text-center w-100">
              <span
                className={`fw-medium ${isActive ? "tx-warning" : "text-dark"}`}
              >
                {option.label}
              </span>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default StyledCheckBoxButton;
