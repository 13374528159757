import { useState, useEffect } from "react";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import Edit from "./Edit";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import ProfileHeader from "./ProfileHeader";
import File from "./FilePage";
import RequiredCompetencies from "../requiredCompetencies";
import axios from "../api/api";

const Profile = (props) => {
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState({});

  useEffect(() => {
    axios
      .get(`/organisation-roles/${props.match.params.uuid}`)
      .then(({ data }) => {
        setRole(data.data.organisationRole);
      })
      .catch(errorSwal)
      .then(() => setLoading(false));
  }, []);

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage={role.name}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "organisation-roles", name: "Roles" },
          {
            link: `organisation-roles/${role.uuid}`,
            name: role.name,
            active: true,
          },
        ]}
      />
      <div className="row row-sm">
        <div className="col-lg-8">
          <ProfileHeader role={role} />
          <ShowPage role={role} setRole={setRole} {...props} />
        </div>
        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <TabList>
            <Tab
              link={`/organisation-roles/${props.match.params.uuid}/details`}
              icon="fa fa-gift"
              active={props.match.params.page === "details"}
            >
              Details
            </Tab>
            <Tab
              link={`/organisation-roles/${props.match.params.uuid}/role-description`}
              icon="fa fa-gift"
              active={props.match.params.page === "role-description"}
            >
              Role Description
            </Tab>
            <Tab
              link={`/organisation-roles/${props.match.params.uuid}/required-competencies`}
              icon="fa fa-gift"
              active={props.match.params.page === "required-competencies"}
            >
              Required Competencies
            </Tab>
          </TabList>
        </div>
      </div>
    </>
  );
};

const ShowPage = ({ role, match, setRole }) => {
  switch (match.params.page) {
    case "details":
      return <Edit setRole={setRole} initialValues={role} />;
    case "role-description":
      return <File role={role} setRole={setRole} />;
    case "required-competencies":
      return (
        <RequiredCompetencies
          model="App\Models\OrganisationRole"
          id={role.id}
        />
      );
    default:
      return null;
  }
};

export default Profile;
