const QUICK_LINKS = [
  {
    heading: "Organisation Details",
    icon: "fa fa-building-o",
    links: [
      { link: "/trackingsites", text: "Tracking Sites" },
      { link: "/branches", text: "Branches" },
      { link: "/divisions", text: "Divisions" },
    ],
  },
  {
    heading: "Operational Details",
    icon: "fa fa-laptop",
    links: [
      { link: "/jobtypes", text: "Job Types" },
      { link: "/billable-items", text: "Billable Items" },
      { link: "/billable-item-units", text: "Billable Items Units" },
      { link: "/calibration-units", text: "Calibration Units" },
      { link: "/timesheet-approval-users", text: "Timesheet Approvers" },
      { link: "/standard-pricing", text: "Standard Price List" },
      { link: "/industries", text: "Industries" },
      { link: "/templates", text: "Templates" },
      { link: "/client-feedback-types", text: "Client Feedback Types" },
      { link: "/supplier-types", text: "Supplier Types" },
      { link: "/client-types", text: "Client Business Activities" },
      {
        link: "/required-project-information",
        text: "Required Project Information",
      },
      { link: "/groups", text: "Groups" },
      { link: "/custom-jobs", text: "Custom Jobs" },
      { link: "/tender-types", text: "Tender Types" },
      { link: "/email-templates", text: "Email Templates" },
      { link: "/topics", text: "Topics" },
      { link: "/docket-types", text: "Docket Types" },
      {
        link: "/safety-initiatives",
        text: "Safety Initiatives",
      },
      {
        link: "/test-suites",
        text: "Test Suites",
        jobType: "App\\Models\\TestRequest",
      },
      { link: "/branding-themes", text: "Branding Themes" },
      { link: "/registers", text: "Custom Registers" },
      {
        link: "/project-forms",
        text: "Project Form",
        jobType: "App\\Models\\ProjectForm",
      },
      {
        link: "/project-types",
        text: "Project Types",
        jobType: "App\\Models\\ProjectForm",
      },
      {
        link: "/test-worksheets",
        text: "Test Worksheets",
        jobType: "App\\Models\\TestRequest",
      },
      {
        link: "/expected-test-times",
        text: "Expected Test Times",
        jobType: "App\\Models\\TestRequest",
      },
      {
        link: "/user-credit-cards",
        text: "User Credit Cards",
      },
      {
        link: "/ticket-types",
        text: "Ticket Types",
      },
      {
        link: "/external-test-request-templates",
        text: "External Test Request Templates",
        jobType: "App\\Models\\TestRequest",
      },
      {
        link: "/services",
        text: "Services",
        jobType: "App\\Models\\Opportunity",
      },
      {
        link: "/chain-of-custody-template",
        text: "Chain of Custody Template",
        jobType: "App\\Models\\TestRequest",
      },
    ],
  },
  {
    heading: "Accounts",
    icon: "fa fa-money",
    links: [
      { link: "/accounts/settings", text: "Accounts Settings" },
      { link: "/chart-of-accounts", text: "Chart of Accounts" },
      { link: "/invoices", text: "Invoices" },
      { link: "/purchases", text: "Purchases" },
      { link: "/contract-agreements", text: "Contract Agreements" },
      { link: "/tracking-categories", text: "Tracking Categories" },
      { link: "/deductions", text: "Deductions" },
      { link: "/budget-templates", text: "Budget Templates" },
      { link: "/upload-purchase-order", text: "Upload Purchase Order" },
      { link: "/invoice-reminders", text: "Invoice Reminders" },
      { link: "/inbound-accounts-payable", text: "Inbound Accounts Payable" },
      { link: "/upload-purchases", text: "Upload Purchases" },
    ],
  },
  {
    heading: "Assets",
    icon: "fa fa-automobile",
    links: [
      { link: "/equipment", text: "Equipment" },
      { link: "/equipment-types", text: "Equipment Types" },
      { link: "/equipment-type-groups", text: "Equipment Type Groups" },
    ],
  },
  {
    heading: "Human Resources",
    icon: "fa fa-user-o",
    links: [
      { link: "/users", text: "Staff" },
      { link: "/organisation-roles", text: "Roles" },
      { link: "/positions", text: "Positions" },
      { link: "/employment-details", text: "Employment Details" },
      { link: "/users/invite", text: "Invite Staff to Join Organisation" },
      { link: "/pay-runs", text: "Pay Runs" },
      { link: "/skill-categories", text: "Skill Categories" },
      { link: "/cpir-categories", text: "CPIR Categories" },
      { link: "/cpir-types", text: "CPIR Types" },
      { link: "/staff-matrix", text: "Staff Matrix" },
      {
        link: "/qualifications",
        text: "Licenses/Registrations/Qualifications",
      },
      { link: "/leave-reasons", text: "Leave Reasons" },
      { link: "/public-holidays", text: "Public Holidays" },
      { link: "/penalty-rates", text: "Penalty Rates" },
      { link: "/employment-awards", text: "Employment Awards" },
      { link: "/permission-groups", text: "Permission Groups" },
      { link: "/allowance-types", text: "Allowance Types" },
      {
        link: "/performance-review-templates",
        text: "Performance Review Templates",
      },
      {
        link: "/onboarding-steps",
        text: "Onboarding Steps",
      },
      {
        link: "/required-competencies",
        text: "Required Competencies",
      },
    ],
  },
];

export default QUICK_LINKS;
