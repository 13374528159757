import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { AuthUser } from "../user/userTypes";
import errorSwal from "./errorSwal";

const SwitchOrganisation = () => {
  const { id } = useParams<{ id: string }>();
  const params = new URLSearchParams(window.location.search);

  const { setUser } = useAuth();

  const { takeAction }: IUseApi<{}, { data: AuthUser }> = useApi();

  const history = useHistory();

  useEffect(() => {
    takeAction("store", `users/organisations/active`, {
      id,
    })
      .then(({ data }) => {
        setUser(data.data);
        localStorage.setItem("recent_organisation", data.data.active_organisation.uuid);
        history.push(params.get("link") ?? "/");
      })
      .catch((err) => {
        errorSwal(err);
        history.goBack();
      });
  }, []);

  return <div>Changing Organisation...</div>;
};

export default SwitchOrganisation;
