import React, { useState, useRef } from "react";
import { useSearchBox, UseSearchBoxProps } from "react-instantsearch";
import { FaFilter, FaSearch } from "react-icons/fa";
import { Button } from "reactstrap";

interface BossSearchBoxProps extends UseSearchBoxProps {
  toggle?: () => void;
  children?: React.ReactNode;
}

const BossSearchBox: React.FC<BossSearchBoxProps> = ({
  toggle,
  children,
  ...props
}) => {
  const { query, refine } = useSearchBox({ ...props });

  const [inputValue, setInputValue] = useState(query);

  const inputRef = useRef<HTMLInputElement>(null);

  const setQuery = (newQuery: string) => {
    setInputValue(newQuery);
    refine(newQuery);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleReset = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setQuery("");

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      <form
        className="d-flex search-box flex-grow-1"
        action=""
        role="search"
        noValidate
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        <input
          className="form-control w-100 no-focus"
          ref={inputRef}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder="Search"
          spellCheck={false}
          maxLength={512}
          type="search"
          value={inputValue}
          onChange={(event) => setQuery(event.currentTarget.value)}
          autoFocus
        />

        {toggle && (
          <>
            <Button
              className="text-white btn btn-primary ms-auto filter-button"
              onClick={toggle}
            >
              <FaFilter className="tx-16" />
            </Button>
          </>
        )}
        <Button type="submit" className="text-white btn btn-primary ms-auto">
          <FaSearch />
        </Button>
      </form>
      {children}
    </>
  );
};

export default BossSearchBox;
