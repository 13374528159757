import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Field } from "redux-form";
import swal from "sweetalert";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import { useAuth } from "../../context/auth-context";
import SelectInput from "../form/SelectInput";

const TransferTasksModal = (props) => {
  const { userId: uuid } = useParams();

  const { user: authUser } = useAuth();

  const { data: users, setUrl: setUsersUrl } = useApi("", []);

  const { takeAction, data: tasks, setData: setTasks, setUrl } = useApi(``, []);

  useEffect(() => {
    let query = "";
    let usersQuery = "";
    if (
      authUser.active_organisation.id !==
      props.user.organisation_user.organisation.id
    ) {
      query = `labour_hire_organisation_id=${props.user.organisation_user.organisation.id}`;
      usersQuery = `filter[labour_hire_organisation]=${props.user.organisation_user.organisation.id}`;
    }

    setUrl(`users/${uuid}/incomplete-tasks?${query}`);

  setUsersUrl(`users?filter[employed]=true&${usersQuery}`);
  }, [props.user]);

  const { toggle } = props;

  const onSubmit = (values) => {
    let query = "";

    if (
      authUser.active_organisation.id !==
      props.user.organisation_user.organisation.id
    ) {
      query = `?organisation_id=${props.user.organisation_user.organisation.id}`;
    }

    return takeAction(
      "update",
      `users/${uuid}/incomplete-tasks${query}`,
      values,
    )
      .then(({ data }) => {
        swal({
          title: "Job Dispatched",
          text: "We have just dispatched a job to update these tasks. This can take a while so we'll email you when it's complete.",
          icon: "success",
        });

        setTasks(data.data);

        toggle();
      })
      .catch(errorSwal);
  };

  const tasksToUpdate = tasks.filter(({ count }) => count > 0);

  const initialValues = {
    tasks: tasksToUpdate.map(({ uuid, type }) => ({
      uuid,
      type,
      manager_id: props.user.id,
    })),
  };

  return (
    <FormModal
      {...props}
      onSubmit={onSubmit}
      form="IncompleteTasks"
      title={`Transfer Tasks in ${props.user.organisation_user.organisation.name}`}
      initialValues={initialValues}
    >
      {({ change }) => {
        return tasksToUpdate.map((task, index) => {
          if (task.count === 0) {
            return null;
          }

          return (
            <React.Fragment key={task.type}>
              <div className="form-group col-12">
                <Field
                  component={SelectInput}
                  options={users.map((user) => ({
                    value: user.id,
                    label: user.full_name,
                  }))}
                  name={`tasks[${index}].manager_id`}
                  label={`${task.type} (${
                    task.type === "group-leader"
                      ? task.details.title
                      : task.count
                  })`}
                />
              </div>
              <div className="form-group col-12">
                <Field
                  component="input"
                  type="hidden"
                  name={`tasks[${index}].type`}
                />
              </div>
            </React.Fragment>
          );
        });
      }}
    </FormModal>
  );
};

export default TransferTasksModal;
