import dayjs from "dayjs";
import _ from "lodash";
import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from "@hello-pangea/dnd";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { checkMobileAndTablet } from "../utils/deviceDetection";
import errorSwal from "../utils/errorSwal";
import LoadingOverlay from "../utils/LoadingOverlay";
import DraggableJob from "./DraggableJob";
import useUserPriorityJobs from "./hooks/useUserPriorityJobs";
import useUserJobs from "./hooks/useUserJobs";
import flattenInfinitePages from "../utils/flattenInfinitePages";
import useObserver from "../hooks/useObserver";
import CustomScaleLoader from "../utils/scaleLoader";
import { useQueryClient } from "react-query";

const JobOrder = ({ userUuid }: { userUuid: string }) => {
  const { takeAction, loading }: IUseApi = useApi();

  const queryClient = useQueryClient();

  const [search, setSearch] = useState("");

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (
      result.destination.droppableId === "non-priority" &&
      result.source.droppableId === "non-priority"
    ) {
      return;
    }

    if (result.destination.droppableId === "priority") {
      takeAction("update", `jobs/${result.draggableId}/job-priorities`, {
        priority: result.destination?.index,
      })
        .then(() => {
          queryClient.invalidateQueries("user-priority-jobs");
          queryClient.invalidateQueries("user-jobs");
        })
        .catch(errorSwal);

      return;
    }

    takeAction("destroy", `job-priorities/${result.draggableId}`)
      .then(({ data }) => {
        queryClient.invalidateQueries("user-priority-jobs");
        queryClient.invalidateQueries("user-jobs");
      })
      .catch(errorSwal);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="search-box shadow-sm flex-grow-1 d-flex">
            <input
              autoComplete="off"
              placeholder="Search..."
              className="form-control w-100"
              type="search"
              style={{ zIndex: 1 }}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              className="btn btn-primary ms-auto"
              type="button"
              style={{ height: "40px", width: "40px" }}
              title="Search"
            >
              <i className={`fa fa-search`} />
            </button>
          </div>
        </div>

        <>
          <DroppableJobs
            indexHook={useUserPriorityJobs}
            title="Priority"
            search={search}
            droppableId="priority"
            loading={loading}
            userUuid={userUuid}
          />
          <DroppableJobs
            indexHook={useUserJobs}
            filters="&filter[non_prioritised]=true"
            title="Non-Priority"
            search={search}
            droppableId="non-priority"
            loading={loading}
            userUuid={userUuid}
          />
        </>
      </div>
    </DragDropContext>
  );
};

interface DroppableJobsProps {
  droppableId: "priority" | "non-priority" | "your-jobs";
  title: "Priority" | "Non-Priority" | "Your Jobs";
  loading: boolean;
  indexHook: Function;
  search: string;
  userUuid: string;
  filters?: string;
}

const DroppableJobs = ({
  droppableId,
  title,
  loading,
  indexHook,
  search,
  userUuid,
  filters,
}: DroppableJobsProps) => {
  const jobTypes = [
    "App\\Models\\JobType",
    "App\\Models\\CustomJob",
    "App\\Models\\StandardJobType",
    "App\\Models\\Tender",
  ]
    .map((type) => encodeURIComponent(type))
    .join(",");

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isFetching } =
    indexHook(
      search +
        `&filter[status]=0,1&filter[job_type_type]=${jobTypes}` +
        filters,
      userUuid,
    );

  const intersection = useObserver(
    () => !isFetchingNextPage && hasNextPage && fetchNextPage(),
  );

  const jobs = flattenInfinitePages(data);

  const amountOfHoursLeft = _.sumBy(jobs, "estimated_hours_left");

  return (
    <div className="col-lg-6 h-100 mb-3 mb-lg-0">
      <Droppable
        isDropDisabled={!!checkMobileAndTablet()}
        droppableId={droppableId}
      >
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
          return (
            <div className="position-relative h-100">
              <LoadingOverlay loading={loading} />
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="bg-white p-3 border"
              >
                <p className="slim-card-title mb-1">{title}</p>
                <p className="mb-0">
                  <span className="tx-inverse">{amountOfHoursLeft}</span> hours
                  of work remaining for{" "}
                  <span className="tx-inverse">{jobs?.length}</span> jobs
                </p>
                <div
                  className="mt-4 space-y-3 custom-scrollbar"
                  style={{ maxHeight: "70vh", overflow: "auto" }}
                >
                  {jobs?.map((job: any, index: number) => {
                    return (
                      <div key={job.uuid}>
                        <Draggable
                          isDragDisabled={checkMobileAndTablet()}
                          draggableId={job.uuid}
                          index={index}
                        >
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot,
                          ) => {
                            return (
                              <DraggableJob
                                provided={provided}
                                snapshot={snapshot}
                                job={job}
                              />
                            );
                          }}
                        </Draggable>
                      </div>
                    );
                  })}
                  <div ref={intersection} style={{ height: "10px" }} />
                  {provided.placeholder}
                  {isFetchingNextPage && (
                    <CustomScaleLoader>Fetching More Data...</CustomScaleLoader>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Droppable>
    </div>
  );
};

const searchValues: { column: string; format?: (job: any) => string }[] = [
  {
    column: "name",
  },
  {
    column: "number",
  },
  {
    column: "project.full_name",
  },
  {
    column: "scheduled_finish_date",
    format: (job) => dayjs(job.scheduled_finish_date).format("DD/MM/YYYY"),
  },
];

export default JobOrder;
