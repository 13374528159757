import axios from "../api/api";
import { change, destroy } from "redux-form";
import {
  FETCH_PRODUCTIVITY,
  FETCH_TIMESHEETS,
  MODIFY_TIMESHEET_DATE,
} from "./types";
import { EDIT_TIMESHEET_FORM } from "../components/timesheets/TimesheetProfile";
import dayjs from "dayjs";

export const fetchUserTimesheets = (userId) => (dispatch) => {
  return axios
    .get(`/timesheets?userId=${userId}`)
    .then(({ data }) => data.data)
    .then((timesheets) =>
      dispatch({
        type: FETCH_TIMESHEETS,
        payload: timesheets,
      }),
    );
};

export const modifyDate = (user, date) => (dispatch) => {
  dispatch({
    type: FETCH_PRODUCTIVITY,
    payload: [],
  });

  dispatch({ type: MODIFY_TIMESHEET_DATE, payload: date });

  dispatch(change("AddTimesheet", "date", dayjs(date).format("YYYY-MM-DD")));

  if (user?.settings?.load_work_day === false) {
    return;
  }
};

export const clearTimesheetForm = () => (dispatch) => {
  dispatch(destroy(EDIT_TIMESHEET_FORM));
};
