import { useEffect } from "react";
import SkillLevelFields from "./SkillLevelFields";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

const SkillLevels = (props) => {
  const { fields, initialValues } = props;

  useEffect(() => {
    if (!initialValues) {
      fields.push({});
    }
  }, []);

  const dragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    fields.move(source.index, destination.index);
  };

  return (
    <>
      <div className="col-12">
        <p className="tx-inverse tx-semibold">Skill Levels</p>
      </div>

      <DragDropContext onDragEnd={dragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="space-y-5"
            >
              {fields.map((field, index) => {
                return (
                  <Draggable key={index} draggableId={field} index={index}>
                    {(provided) => {
                      return (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className="p-3 bg-white rounded-lg shadow-sm border"
                        >
                          <div className="row">
                            <SkillLevelFields
                              key={index}
                              {...props}
                              field={field}
                              index={index}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="col-12 d-flex mt-3">
        <button
          type="button"
          className="ms-auto btn btn-link"
          onClick={() => fields.push({})}
        >
          Add Skill Level
        </button>
      </div>
    </>
  );
};

export default SkillLevels;
