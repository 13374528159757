import {
  Field,
  FormSubmitHandler,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import RenderField from "../utils/renderField";
import { IDocument } from "../documents/documentTypes";
import filterOption from "../../utils/filterOption";
import SelectInput from "../form/SelectInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { IUseApi } from "../api/apiTypes";
import {
  ChainOfCustodyTemplateFormValues,
  ChainOfCustodyTemplate as ChainOfCustodyTemplateType,
} from "./chainOfCustodyTypes";
import { connect } from "react-redux";
import formError from "../utils/formError";
import FormErrorAlert from "../form/FormErrorAlert";
import { toast } from "react-toastify";

const ChainOfCustodyTemplate = ({ url }: { url?: string }) => {
  const { data, setData, loading }: IUseApi<ChainOfCustodyTemplateType> =
    useApi("chain-of-custody-template", null, true);

  const { takeAction }: IUseApi = useApi(
    "chain-of-custody-template",
    null,
    true,
  );

  const onSubmit: FormSubmitHandler<ChainOfCustodyTemplateFormValues> = (
    values,
  ) => {
    return takeAction("update", "chain-of-custody-template", values)
      .then(({ data }) => {
        setData(data.data);
        toast.success("Chain of Custody Template updated successfully");
      })
      .catch(formError);
  };

  if (loading) {
    return <Spinner loading={true} />;
  }

  const microsoftViewUrl =
    data?.document || url
      ? `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
          url || data?.document?.inline_link || "",
        )}`
      : null;

  return (
    <>
      <HeaderPage titlePage="Chain of Custody Template" crumbs={[]} />
      <WrappedChainOfCustodyTemplateForm
        initialValues={data}
        onSubmit={onSubmit}
        form="chainOfCustodyTemplateForm"
      />

      {microsoftViewUrl && (
        <iframe
          style={{ height: "100vh" }}
          className="w-100 mt-5 rounded-lg shadow"
          src={microsoftViewUrl}
        />
      )}
    </>
  );
};

const ChainOfCustodyTemplateForm = (
  props: InjectedFormProps<ChainOfCustodyTemplateFormValues>,
) => {
  const { handleSubmit, change, initialValues } = props;

  const {
    data: sheets,
    setUrl,
    loading,
  } = useApi(
    initialValues?.document_id
      ? `workbook-sheets?id=${initialValues.document_id}`
      : "",
    [],
    true,
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="form-group col-12">
          <Field
            name="document_id"
            component={SelectInput}
            url="/documents?filter[extensions]=xlsx,xlsm&filter[is_current]=true"
            label="Document"
            required
            validate={required}
            filterOption={filterOption}
            changeValue={({ value, url }: { value: number; url: string }) => {
              if (value) {
                setUrl(`workbook-sheets?id=${value}`);
                change("url", url);
                change("sheet_name", "");
              }
            }}
            formatData={(data: IDocument[]) =>
              data.map((d) => ({
                label: (
                  <>
                    <p className="text-dark mb-0">
                      <i className="fa fa-file-excel-o tx-success me-2" />
                      {d.name}
                    </p>
                  </>
                ),
                value: d.id,
                text: d.name,
                url: d.inline_link,
              }))
            }
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            name="beginning_row"
            component={RenderField}
            label="Beginning Row"
            required
            validate={required}
            type="number"
          />
        </div>
        <div className="form-group col-lg-6">
          <Field
            name="sheet_name"
            component={SelectInput}
            label="Sheet Name"
            required
            validate={required}
            loading={loading}
            options={sheets.map((sheet: string) => ({
              label: sheet,
              value: sheet,
            }))}
          />
        </div>
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const WrappedChainOfCustodyTemplateForm =
  reduxForm<ChainOfCustodyTemplateFormValues>({})(ChainOfCustodyTemplateForm);

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("chainOfCustodyTemplateForm");
  const url = selector(state, "url");

  return { url };
};

export default connect(mapStateToProps)(ChainOfCustodyTemplate);
