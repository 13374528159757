import dayjs from "dayjs";
import { useEffect, useState } from "react";
import errorSwal from "../utils/errorSwal";
import InitialSVG from "../utils/InitialSVG";
import axios from "../api/api";
import { DocumentRow } from "../comments/CommentItem";

const ApprovalComments = ({
  approvalRemarks = [],
  className,
  modelType,
  modelId,
}) => {
  const [approvalActions, setApprovalActions] = useState(approvalRemarks || []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modelType) {
      setLoading(true);
      axios
        .get(
          `/approval-action-remarks?filter[approvals.approvable_type]=${encodeURIComponent(
            modelType,
          )}&filter[approvals.approvable_id]=${modelId}`,
        )
        .then(({ data }) => setApprovalActions(data.data))
        .catch((err) => errorSwal("Unable to Fetch Approval Comments"))
        .then(() => setLoading(false));
    }
  }, []);

  const actionsWithRemarks = approvalActions.filter(({ remarks }) => remarks);

  if (loading || actionsWithRemarks.length === 0) return null;

  return (
    <div className={`card card-recent-messages ${className}`}>
      <div className="card-header">
        <span>Approval Comments</span>
      </div>
      <div className="list-group list-group-flush">
        {actionsWithRemarks.map((action) => {
          return (
            <div className="list-group-item" key={action.uuid}>
              <div className="flex-grow-1">
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <InitialSVG
                      size={30}
                      fontSize={12}
                      name={action.user.name}
                    />
                  </div>
                  <div>
                    <h6 className="mb-0">
                      <a href={`mailto:${action.user.email}`}>
                        {action.user.name}
                      </a>
                    </h6>
                    <p className="text-secondary mb-0">
                      {dayjs(action.completed_at).format("DD/MM/YYYY, hh:mma")}
                    </p>
                  </div>
                </div>
              </div>
              <p className="msg">{action.remarks}</p>
              <DocumentRow documents={action.documents} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ApprovalComments;
