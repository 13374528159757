import _ from "lodash";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import Card from "../home/Card";
import HomeData from "../home/HomeData";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";

export interface ActiveDashboard {
  link: string;
  description:
    | "Projects"
    | "Jobs"
    | "Approvals"
    | "Invoices"
    | "Invoices"
    | "Purchases";
  key?:
    | "open-jobs"
    | "overdue-jobs"
    | "to-redo"
    | "to-approve"
    | "opportunities"
    | "tenders"
    | "competencies";
}

const ProfileCards = ({ timesheet }: { timesheet?: { user_uuid: string } }) => {
  const [activeDashboard, setActiveDashboard] = useState<ActiveDashboard>({
    link: "",
    description: "Projects",
  });

  const {
    data: details,
    setUrl,
    loading: initialLoading,
    refreshData,
  }: IUseApi<any[]> = useApi(
    timesheet?.user_uuid ? `home/${timesheet?.user_uuid}` : "",
    [],
  );

  const [dashboardData, setDashboardData] = useState([]);
  const { takeAction, loading, setLoading }: IUseApi = useApi();

  useEffect(() => {
    setDashboardData([]);
    if (activeDashboard.link !== "") {
      takeAction("index", activeDashboard.link)
        .then(({ data }) => {
          setDashboardData(data.data);
        })
        .catch(errorSwal);
    }
  }, [activeDashboard]);

  useEffect(() => {
    setUrl(timesheet?.user_uuid ? `home/${timesheet?.user_uuid}` : "");
  }, [timesheet]);

  if (!timesheet) {
    return null;
  }

  if (initialLoading) {
    return <CustomScaleLoader>Fetching Data...</CustomScaleLoader>;
  }

  return (
    <>
      {activeDashboard?.link && (
        <div className="d-flex">
          <Button
            className="ms-auto px-0"
            color="link"
            onClick={() => {
              setActiveDashboard({
                link: "",
                description: "Projects",
              });
              setDashboardData([]);
            }}
          >
            ×
          </Button>
        </div>
      )}
      {_.chunk(details, 4).map((detail, i) => {
        return (
          <div className="no-gutters dashboard-chart-one" key={i}>
            <div className="row">
              {detail?.map((d, index) => {
                return (
                  <Card
                    key={index}
                    {...d}
                    activeDashboard={activeDashboard}
                    setActiveDashboard={setActiveDashboard}
                    setFetchingDashboardData={setLoading}
                    fetchingDashboardData={loading}
                  />
                );
              })}
            </div>
          </div>
        );
      })}

      {activeDashboard?.link && (
        <>
          {loading ? (
            <CustomScaleLoader>Fetching data...</CustomScaleLoader>
          ) : (
            <HomeData
              activeDashboard={activeDashboard}
              dashboardData={dashboardData}
              setDashboardData={setDashboardData}
              refreshData={refreshData}
              userUuid={timesheet?.user_uuid}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProfileCards;
