import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import errorSwal from "../utils/errorSwal";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";

type ExpectedTestTime = {
  id?: number;
  name: string;
  minutes: string;
  order: number;
  test_status_id: number;
};

type Status = {
  name: string;
  id: number;
};

const ExpectedTestTimeProfile = () => {
  const { method } = useParams<{ method: string }>();

  const { takeAction }: IUseApi = useApi();

  const { data, setData }: IUseApiWithData<ExpectedTestTime[]> = useApi(
    `test-methods/${encodeURIComponent(method)}/expected-test-times`,
    [],
  );

  const { data: statuses }: IUseApiWithData<Status[]> = useApi(
    `test-methods/${encodeURIComponent(method)}/statuses`,
    [],
  );

  const filteredStatuses = statuses.filter(
    (status) => !data.map((d) => d.test_status_id).includes(status.id),
  );

  return (
    <>
      <HeaderPageAdminFilter
        titlePage={`Expected Test Times for ${method}`}
        crumbs={[
          {
            link: "/expected-test-times",
            name: "Expected Test Times",
          },
          {
            name: method,
            link: `/expected-test-times/${method}`,
            active: true,
          },
        ]}
      />

      <DragDropContext
        onDragEnd={(result) => {
          if (
            result.destination?.droppableId == "statuses" &&
            result.source.droppableId == "statuses"
          ) {
            return;
          }

          if (!result.destination) {
            return;
          }

          if (result.destination.droppableId == "statuses") {
            const newData = data.filter(
              (item) => item.test_status_id !== parseInt(result.draggableId),
            );

            setData(newData);

            takeAction(
              "update",
              `test-methods/${encodeURIComponent(method)}/expected-test-times`,
              newData,
            ).catch((err) => {
              errorSwal(err);

              setData(data);
            });

            return;
          }

          const droppedStatus = statuses.find(
            (status) => status.id === parseInt(result.draggableId),
          );

          if (!droppedStatus) {
            return;
          }

          const newData = [...data];

          const existingIndex = newData.findIndex(
            (item) => item.test_status_id == droppedStatus.id,
          );

          let existingItem = {
            minutes: "0",
          };

          if (existingIndex !== -1) {
            // If the item already exists, remove it first
            newData.splice(existingIndex, 1);

            existingItem = data[existingIndex];
          }

          newData.splice(result.destination.index, 0, {
            name: droppedStatus.name,
            minutes: existingItem?.minutes,
            test_status_id: droppedStatus.id,
            order: result.destination.index,
          });

          const ordered = newData.map((item, index) => ({
            ...item,
            order: index,
          }));

          setData(ordered);

          takeAction(
            "update",
            `test-methods/${encodeURIComponent(method)}/expected-test-times`,
            ordered,
          ).catch((err) => {
            setData(data);
          });
        }}
      >
        <div className="row">
          <Droppable droppableId="expected-test-times">
            {(provided, snapshot) => (
              <div
                className="col-lg-6"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div
                  className={`${
                    snapshot.isDraggingOver ? "bg-gray-100" : "bg-white"
                  } rounded border p-3 tn-300 post-group mt-0`}
                >
                  <p className="slim-card-title">Expected Test Times</p>
                  {data.map((item, index) => (
                    <ExpectedTestTime
                      key={item.test_status_id}
                      item={item}
                      index={index}
                      data={data}
                      setData={setData}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>

          <Droppable droppableId="statuses">
            {(provided, snapshot) => (
              <div
                className="col-lg-6"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div
                  className={`${
                    snapshot.isDraggingOver ? "bg-gray-100" : "bg-white"
                  }   rounded border p-3 space-y-3 tn-300`}
                >
                  <p className="slim-card-title">Statuses for {method}</p>
                  {filteredStatuses.map((status, index) => (
                    <Draggable
                      key={status.id.toString()}
                      draggableId={status.id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            className={`border p-3 rounded-lg position-relative tn-300 ${
                              snapshot.isDragging
                                ? "shadow bg-gray-200"
                                : "shadow-sm bg-white"
                            }`}
                          >
                            <p className="mb-0 text-dark">{status.name}</p>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  );
};

const ExpectedTestTime = ({
  item,
  index,
  data,
  setData,
}: {
  item: ExpectedTestTime;
  index: number;
  data: ExpectedTestTime[];
  setData: (data: ExpectedTestTime[]) => void;
}) => {
  const { method } = useParams<{ method: string }>();

  const { takeAction }: IUseApi = useApi();

  return (
    <Draggable
      key={item.test_status_id.toString()}
      draggableId={item.test_status_id.toString()}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`post-item ${
            snapshot.isDragging ? "shadow bg-gray-200" : ""
          }`}
        >
          <span className="post-date">Status {index + 1}</span>
          <p className="post-title text-dark">{item.name}</p>
          {index != data.length - 1 && (
            <div className="w-50">
              <input
                id={`minutes_${index}`}
                name={`minutes[${index}]`}
                className="border-0 w-100 no-focus rounded-lg mt-2"
                placeholder="Add Expected Minutes"
                value={data[index].minutes}
                onChange={(e) => {
                  const newData = [...data];
                  newData[index].minutes = e.target.value;

                  setData(newData);
                }}
                onBlur={() => {
                  takeAction(
                    "update",
                    `test-methods/${encodeURIComponent(
                      method,
                    )}/expected-test-times`,
                    data,
                  ).catch(errorSwal);
                }}
              />
              <label
                htmlFor={`minutes_${index}`}
                className="form-control-label tx-12"
              >
                Expected Minutes
              </label>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default ExpectedTestTimeProfile;
