import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import { useLocation } from "react-router";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { ISubjectItem } from "./subjectTypes";

interface SubjectItemSidebarProps {
  items?: ISubjectItem[];
  setItems: (items: ISubjectItem[]) => void;
}
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SubjectItemSidebar = ({ items, setItems }: SubjectItemSidebarProps) => {
  const { takeAction, loading }: IUseApi = useApi();
  const location = useLocation();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const initialItems = items;

    const reordered = reorder(
      items ?? [],
      result.source.index,
      result.destination.index,
    ).map((item, index) => {
      return {
        ...item,
        order: index,
      };
    });

    setItems(reordered);

    takeAction("update", "subject-item-orders", reordered).catch((err) => {
      errorSwal(err);
      setItems(initialItems ?? []);
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="drop">
        {(provided, context) => (
          <div
            className="position-fixed d-none d-lg-block custom-scrollbar"
            style={{
              left: "30px",
              top: "240px",
              maxHeight: "600px",
              overflowY: "auto",
            }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items?.map((item, index) => {
              return (
                <Draggable
                  key={`${index}`}
                  draggableId={`${index}`}
                  index={index}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <p className="mb-1">
                          <a
                            className="slim-card-title"
                            key={item?.uuid}
                            href={`${location.pathname}#${item.uuid}`}
                          >
                            {item?.name && item.name.length > 25
                              ? `${item.name?.slice(0, 25)}...`
                              : item.name ?? "-"}
                          </a>
                        </p>
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SubjectItemSidebar;
