import { useEffect, useState } from "react";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import { OnboardingStep } from "./onboardingStepTypes";
import { BiPlus } from "react-icons/bi";
import errorSwal from "../utils/errorSwal";
import OnboardingStepForm, {
  OnboardingStepFormProps,
} from "./OnboardingStepForm";
import { FormSubmitHandler } from "redux-form";
import { CustomField } from "../customFields/customFieldTypes";
import { toast } from "react-toastify";
import formError from "../utils/formError";

const OnboardingSteps = () => {
  const {
    data: steps,
    refreshData,
    setData: setSteps,
  }: IUseApiWithData<OnboardingStep[]> = useApi("/onboarding-steps", [], true);

  const { takeAction }: IUseApi = useApi();

  const [selectedStep, setSelectedStep] = useState<OnboardingStep>();

  useEffect(() => {
    if (steps.length > 0 && !selectedStep) {
      setSelectedStep(steps[0]);
    }
  }, [steps]);

  const onSubmit: FormSubmitHandler<OnboardingStep, OnboardingStepFormProps> = (
    values,
  ) => {
    return takeAction("update", `onboarding-steps/${values?.uuid}`, {
      ...values,
      custom_fields: values.custom_fields.map((field: any, index: number) => {
        return {
          ...field,
          order: index,
        };
      }),
    })
      .then(({ data }) => {
        refreshData();
        setSelectedStep(data.data);
        toast.success(`${selectedStep?.name} updated successfully`);
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage titlePage="Onboarding Steps" crumbs={[]} />

      <div className="d-flex space-x-3">
        {steps.map((step) => {
          return (
            <button
              type="button"
              className={`${
                selectedStep?.uuid === step.uuid
                  ? "bg-teal text-white shadow-inner money"
                  : "bg-white text-secondary shadow-sm"
              } border-0 px-3 tx-18 text-secondary py-2 rounded-lg d-flex items-center tn-300 border`}
              onClick={() => setSelectedStep(step)}
            >
              {step.step_number} {step.name}
            </button>
          );
        })}
        <button
          className="bg-white px-3 py-2 border-0 d-flex align-items-center tx-16 rounded-lg text-secondary"
          type="button"
          onClick={() => {
            return takeAction("store", "onboarding-steps", {
              step_number: steps.length + 1,
            })
              .then(({ data }) => {
                setSteps([...steps, data.data]);
                setSelectedStep(data.data);
              })
              .catch(errorSwal);
          }}
        >
          <BiPlus className="me-2 tx-22" />
          Add Step
        </button>
      </div>
      <div className="mt-5">
        <OnboardingStepForm
          setSteps={setSteps}
          setSelectedStep={setSelectedStep}
          initialValues={{
            ...selectedStep,
            custom_fields:
              selectedStep?.custom_fields &&
              selectedStep?.custom_fields?.length > 0
                ? selectedStep?.custom_fields
                : ([{}] as CustomField[]),
          }}
          onSubmit={onSubmit}
          steps={steps}
        />
      </div>
    </>
  );
};

export default OnboardingSteps;
