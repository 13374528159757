import { useMemo } from "react";
import { useAuth } from "../../context/auth-context";
import { IntegrationType } from "../integrations/integrationTypes";
import NotificationSettings from "../notifications/NotificationSettings";
import Tabs from "../tabs/Tabs";
import { Tab } from "../tabs/tabTypes";
import Invites from "./Invites";
import UserDetail from "./UserDetail";
import UserGoals from "./UserGoals";
import UserPayroll from "./UserPayroll";
import UserSettings from "./UserSettings";
import UserSignature from "./UserSignature";
import UserTokens from "./userTokens";

export interface UserDetailTabsProps {
  setUser: (user: any) => void;
  user: any;
  initialValues: any;
}

const UserDetailTabs = (props: UserDetailTabsProps) => {
  const { user } = useAuth();

  const tabs: Tab[] = useMemo(() => {
    const tabs: Tab[] = [
      {
        title: "Information",
        component: <UserDetail key={props.user.id} {...props} />,
      },
      {
        title: "Settings",
        component: (
          <UserSettings
            {...props}
            initialValues={
              props.initialValues.settings
                ? {
                    ...props.initialValues.settings,
                    auto_fill_purchase_branch:
                      props.initialValues.settings
                        ?.auto_fill_purchase_branch === undefined
                        ? true
                        : props.initialValues.settings
                            ?.auto_fill_purchase_branch,
                  }
                : {
                    load_work_day: true,
                    auto_fill_purchase_branch: true,
                  }
            }
          />
        ),
      },
      {
        title: "Signature",
        component: <UserSignature {...props} />,
      },
      {
        title: "Tokens",
        component: <UserTokens />,
      },
      {
        title: "Goals",
        component: <UserGoals uuid={props.user.uuid} />,
      },
    ];

    if (
      user?.active_organisation.integration_types.includes(
        IntegrationType.Payroll,
      ) &&
      props.user.is_primary_organisation
    ) {
      tabs.push({
        title: "Payroll",
        component: <UserPayroll form="UserPayrollForm" />,
      });
    }

    if (user?.id === props.initialValues.id) {
      tabs.push({
        title: "Email Settings",
        component: <NotificationSettings />,
      });
    }

    if (user?.id === props.initialValues.id) {
      tabs.push({
        title: "Invites",
        component: <Invites />,
      });
    }

    return tabs;
  }, [user, props.user]);

  return <Tabs tabs={tabs} />;
};

export default UserDetailTabs;
