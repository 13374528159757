import _ from "lodash";
import Select from "react-select";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import StyledCheckBoxButton from "../form/StyledCheckBoxButton";
import {
  AccessControlListItem,
  AccessControlListOption,
  MethodWithOptions,
} from "./accessControlListItemTypes";

interface Subject {
  id: number;
  acl_items: AccessControlListItem[];
  uuid: string;
}

interface AccessControlListProps<T extends Subject = Subject> {
  subject: T;
  setSubject: (subject: T) => void;
  subjectType: string;
}

const formatMethodLabel = (method: string): string => {
  // Convert snake_case to Title Case
  return method
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const AccessControlList = <T extends Subject = Subject>({
  subject,
  setSubject,
  subjectType,
}: AccessControlListProps<T>) => {
  const { takeAction, loading }: IUseApi = useApi();
  const { data, loading: fetchingOptions } = useApi("acl-options", [], true);
  const aclOptions = data as AccessControlListOption[];

  const updateAcl = (
    model: string,
    method: string,
    checked: boolean,
    uuid?: string,
    modelId?: number,
  ) => {
    return takeAction(
      checked ? "destroy" : "store",
      checked
        ? `access-control-list-items/${uuid}`
        : "access-control-list-items",
      {
        subject_type: subjectType,
        subject_id: subject.id,
        model,
        method,
        model_id: modelId,
      },
    ).then(({ data }) => {
      setSubject({
        ...subject,
        acl_items: checked
          ? subject.acl_items.filter((item) => item.uuid !== uuid)
          : [...subject.acl_items, data.data],
      });
    });
  };

  if (fetchingOptions) {
    return <CustomScaleLoader>Fetching Options...</CustomScaleLoader>;
  }

  return (
    <div className="space-y-5">
      {_.sortBy(aclOptions, (i) => i.label.toLowerCase()).map((item) => (
        <div key={item.model}>
          <p className="text-dark fw-bolder mb-3">{item.label}</p>
          <div className="d-flex flex-wrap gap-3">
            {item.methods.map((method: MethodWithOptions) => {
              if (method.options) {
                const currentItems = subject.acl_items.filter(
                  (subjectItem) =>
                    subjectItem.model === item.model &&
                    subjectItem.method === method.name,
                );
                return (
                  <RenderSelect
                    key={method.name}
                    method={method}
                    item={item}
                    currentItems={currentItems}
                    updateAcl={updateAcl}
                  />
                );
              }

              const currentItem = subject.acl_items.find(
                (subjectItem) =>
                  subjectItem.model === item.model &&
                  subjectItem.method === method.name,
              );
              return (
                <RenderInput
                  key={method.name}
                  method={method}
                  item={item}
                  currentItem={currentItem}
                  updateAcl={updateAcl}
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

// Render Select Component
const RenderSelect = ({
  method,
  item,
  currentItems,
  updateAcl,
}: {
  method: MethodWithOptions;
  item: AccessControlListOption;
  currentItems: AccessControlListItem[];
  updateAcl: (
    model: string,
    method: string,
    checked: boolean,
    uuid?: string,
    modelId?: number,
  ) => Promise<void>;
}) => {
  return (
    <div key={method.name} className="w-100">
      <label className="form-control-label tx-inverse tx-semibold">
        {formatMethodLabel(method.name)}
      </label>
      <Select
        isMulti
        className="basic-multi-select"
        classNamePrefix="select"
        value={currentItems.map((item) => ({
          value: item.model_id!,
          label: method.options![item.model_id!],
        }))}
        options={Object.entries(method.options!).map(([id, name]) => ({
          value: Number(id),
          label: name,
        }))}
        onChange={async (selectedOptions: any) => {
          const selectedIds =
            selectedOptions?.map((option: any) => option.value) || [];

          // Handle removes
          const itemsToRemove = currentItems.filter(
            (item) => !selectedIds.includes(item.model_id),
          );

          if (itemsToRemove.length) {
            await Promise.all(
              itemsToRemove.map((item) =>
                updateAcl(item.model, method.name, true, item.uuid),
              ),
            );
          }

          // Handle adds
          const idsToAdd = selectedIds.filter(
            (id: number) => !currentItems.find((item) => item.model_id === id),
          );

          if (idsToAdd.length) {
            await Promise.all(
              idsToAdd.map((id: number) =>
                updateAcl(item.model, method.name, false, undefined, id),
              ),
            );
          }
        }}
      />
    </div>
  );
};

// Render Input Component
const RenderInput = ({
  method,
  item,
  currentItem,
  updateAcl,
}: {
  method: MethodWithOptions;
  item: AccessControlListOption;
  currentItem: AccessControlListItem | undefined;
  updateAcl: (
    model: string,
    method: string,
    checked: boolean,
    uuid?: string,
  ) => Promise<void>;
}) => {
  return (
    <StyledCheckBoxButton
      key={method.name}
      input={{
        value: currentItem ? [method.name] : [],
        onChange: (newValue: string[]) => {
          const isChecked = newValue.includes(method.name);
          updateAcl(item.model, method.name, !isChecked, currentItem?.uuid);
        },
      }}
      options={[
        {
          value: method.name,
          label: formatMethodLabel(method.name),
        },
      ]}
      button={({ label }) => (
        <div className="text-center">
          <span className="fw-medium">{label}</span>
        </div>
      )}
    />
  );
};

export default AccessControlList;
