import { useState } from "react";
import { initialize } from "redux-form";
import ReactTable from "../table/ReactTable";
import ModalForm from "./ModalForm";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import { Button } from "reactstrap";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";
import TextButton from "../utils/TextButton";
import { useQueryClient } from "react-query";
const SkillTable = ({ skills, category, loading }) => {
  const [modal, setModal] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState("");

  const { takeAction } = useApi();

  const queryClient = useQueryClient();

  const toggle = (skill) => {
    setSelectedSkill(selectedSkill ? null : skill);
    setModal(!modal);
  };

  const deleteSkill = (skill) => {
    return deleteSwal(skill.skill)
      .then(() => takeAction("destroy", `competency-skills/${skill.uuid}`))
      .then(() => {
        queryClient.invalidateQueries(["competency-skills"]);

        toast.success(`${skill.skill} deleted`);
      })
      .catch(errorSwal);
  };

  const onSubmit = (values, dispatch) => {
    return takeAction("update", `competency-skills/${values.uuid}`, {
      ...values,
      skill_category_id: category.id,
      skill_levels: values.skill_levels.map((level, i) => {
        return {
          ...level,
          level: i + 1,
        };
      }),
    })
      .then(({ data }) => {
        queryClient.invalidateQueries(["competency-skills"]);

        toast.success("Skill updated successfully");

        dispatch(initialize("CompetencySkills", data.data));
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "skill",
      header: "Skill",
      cell: (info) => {
        return (
          <div>
            <TextButton
              className="text-start"
              onClick={() => toggle(info.row.original)}
            >
              {info.row.original.skill}
            </TextButton>
            <small className="d-block">
              {info.row.original.skill_levels.length} level
              {info.row.original.skill_levels.length === 1 ? "" : "s"}
            </small>
          </div>
        );
      },
    },
    {
      accessorKey: "delete",
      header: "Delete",
      cell: (info) => (
        <Button
          color="danger"
          size="sm"
          outline
          onClick={() => deleteSkill(info.row.original)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <ReactTable
        disableSearch
        columns={columns}
        data={skills}
        loading={loading}
      />
      {selectedSkill && (
        <ModalForm
          modal={modal}
          onSubmit={onSubmit}
          toggle={toggle}
          initialValues={selectedSkill}
          form="UPDATE_COMPETENCY_SKILLS"
          title={`Update ${selectedSkill.skill}`}
        />
      )}
    </>
  );
};

export default SkillTable;
