import { useParams } from "react-router-dom";
import PaginatedList from "../pagination/PaginatedList";
import useBranchJobs from "./hooks/useBranchJobs";
import JobTable from "../jobs/JobTable";
import { jobFilters } from "../projectManagers/ProjectManagerJobs";

const BranchJobs = () => {
  const { uuid } = useParams();

  return (
    <PaginatedList
      listName="branchJobsList"
      indexHook={useBranchJobs}
      indexHookArguments={[uuid]}
      originalFilters={jobFilters}
      list={({ data, setSorting, sorting, pages }) => (
        <div className="col-12">
          {/* <p>{pages?.[0]?.meta.total} jobs found</p> */}
          {data?.length > 0 && (
            <JobTable
              showProject={true}
              setSelectedJob={() => {}}
              jobs={data}
              sorting={sorting}
              setSorting={setSorting}
              disablePinning={true}
            />
          )}
        </div>
      )}
    />
  );
};

export default BranchJobs;
