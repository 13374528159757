import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { StaffCompetency } from "../staffCompetencies/staffCompetencyTypes";
import ReactTable from "../table/ReactTable";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const CompetenciesTable = ({
  competencies,
}: {
  competencies: StaffCompetency[];
}) => {
  const columnHelper = createColumnHelper<StaffCompetency>();

  const columns = [
    columnHelper.accessor("skill_level.name", {
      header: "Skill",
      cell: (info) => {
        const competency = info.cell.row.original;

        return (
          <Link to={competency.link} className="text-dark">
            {info.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor("added_on", {
      header: "Date Due",
      cell: (info) => {
        return <span>{dayjs(info.getValue()).format("DD/MM/YYYY")}</span>;
      },
    }),
  ] as ColumnDef<StaffCompetency>[];

  return <ReactTable columns={columns} data={competencies} />;
};

export default CompetenciesTable;
