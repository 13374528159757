import * as _ from "lodash";
import { Field, WrappedFieldArrayProps } from "redux-form";
import SelectInput from "../form/SelectInput";

import renderField from "../utils/renderField";
import density_specs from "./density_specs";
import moisture_specs from "./moisture_specs";
import test_depths from "./test_depths";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import validate, { requiredFields } from "./validate";
import { toast } from "react-toastify";
import { SelectOption } from "../utils/utilTypes";
import { useState, useEffect } from "react";
import errorSwal from "../utils/errorSwal";

export interface UserCompetent {
  competencies: {
    skill_id: number;
  }[];
  supervisors: {
    id: number;
    name: string;
    test_method_id: number;
  }[];
}

const FormNuclearDensometer = (
  props: WrappedFieldArrayProps & {
    change: Function;
    nucForm: any[];
    densityGauges: SelectOption[];
    handleSubmit: Function;
    userCompetent?: UserCompetent;
  },
) => {
  const { takeAction } = useApi();
  const {
    fields,
    handleSubmit,
    densityGauges,
    nucForm,
    change,
    userCompetent,
  } = props;
  const { takeAction: updateStatus, loading: updatingStatus } = useApi();

  // Add state for request queue
  const [saveQueue, setSaveQueue] = useState<(() => Promise<any>)[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  // Process queue when it changes
  useEffect(() => {
    const processQueue = async () => {
      if (saveQueue.length === 0 || isProcessing) return;

      setIsProcessing(true);
      try {
        const nextRequest = saveQueue[0];
        await nextRequest();

        setSaveQueue((prev) => prev.slice(1));
      } catch (error) {
        // errorSwal(error);
      }

      setIsProcessing(false);
    };

    processQueue();
  }, [saveQueue, isProcessing]);

  const canUpdateStatus =
    userCompetent &&
    (userCompetent.competencies.length > 0 ||
      userCompetent.supervisors.length > 0);

  const submit = (index: number) => (values: any) => {
    validate(index, values);

    const nuc = values.nucs[index];

    // Create save function and add to queue instead of executing immediately
    const saveFunction = () =>
      takeAction("update", `/nuclear-densometers/${nuc.uuid}`, nuc)
        .then(() => toast.success("Nuc Updated"))
        .catch(errorSwal);

    setSaveQueue((prev) => [...prev, saveFunction]);
  };

  return (
    <div className="row flex-nowrap" style={{ overflowX: "auto" }}>
      {fields.map((nuc, index) => {
        const nucData = fields.get(index);
        const isQ142A = nucData.test_method === "Q141A";

        return (
          <div key={index} className="col-lg-3 col-md-6 col-12">
            <div className="row align-items-center">
              <div className="col-6 mb-3">
                <h6 className="tx-inverse tx-bold mb-0 p">
                  {nucData.sample_number}
                </h6>
              </div>
              <div className="col-6 mb-3">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => copyPrevious(nucForm, index, change)}
                  disabled={index === 0}
                >
                  Copy Previous
                </button>
              </div>
              <div className="form-group col-12">
                <Field
                  required
                  component={SelectInput}
                  options={densityGauges.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                    disabled: item.disabled,
                  }))}
                  label="Nuclear Densometer"
                  name={`${nuc}.equipment_id`}
                  onBlur={handleSubmit(submit(index))}
                />
                {densityGauges.length === 0 && (
                  <small>
                    No Nuclear Densometers found in your equipment list. Please
                    add one before continuing.
                  </small>
                )}
              </div>
              <div className="form-group col-12">
                <Field
                  required
                  component={renderField}
                  type="number"
                  extraProps={{ step: "0.01" }}
                  label="Layer Depth"
                  name={`${nuc}.layer_depth`}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>
              <div className="form-group col-12">
                <Field
                  required
                  component={SelectInput}
                  options={test_depths}
                  label="Test Depth"
                  name={`${nuc}.test_depth`}
                  onBlur={handleSubmit(submit(index))}
                  order={false}
                />
              </div>

              <div className="form-group col-12  ">
                <Field
                  required
                  component={SelectInput}
                  options={density_specs}
                  order={false}
                  label="Density Spec"
                  name={`${nuc}.density_spec`}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>

              <div className="form-group col-12  ">
                <Field
                  required
                  options={moisture_specs}
                  component={SelectInput}
                  order={false}
                  label="Moisture Spec"
                  name={`${nuc}.moisture_spec`}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>
              <div className="form-group col-6">
                <Field
                  component={renderField}
                  label="Location 1"
                  name={`${nuc}.sample.location_1`}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>
              <div className="form-group col-6">
                <Field
                  component={renderField}
                  label="Location 2"
                  name={`${nuc}.sample.location_2`}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>
              <div className="form-group col-6">
                <Field
                  component={renderField}
                  label="Location 3"
                  name={`${nuc}.sample.location_3`}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>
              <div className="form-group col-6">
                <Field
                  component={renderField}
                  label="Location 4"
                  name={`${nuc}.sample.location_4`}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>

              <div className="form-group col-6  ">
                <Field
                  required
                  component={renderField}
                  type="number"
                  label="Standard DC"
                  name={`${nuc}.standard_density_count`}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>
              <div className="form-group col-6">
                <Field
                  required
                  label="Standard MC"
                  component={renderField}
                  type="number"
                  name={`${nuc}.standard_moisture_count`}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>
              <div className="form-group col-6">
                <Field
                  required
                  component={renderField}
                  type="number"
                  label="DC"
                  name={`${nuc}.density_count`}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>
              {isQ142A && (
                <div className="form-group col-6">
                  <Field
                    component={renderField}
                    type="number"
                    label="DC 2"
                    name={`${nuc}.density_count_2`}
                    onBlur={handleSubmit(submit(index))}
                  />
                </div>
              )}
              <div className="form-group col-6">
                <Field
                  required
                  component={renderField}
                  type="number"
                  label="MC"
                  name={`${nuc}.moisture_count`}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>
              {isQ142A && (
                <div className="form-group col-6">
                  <Field
                    component={renderField}
                    type="number"
                    label="MC 2"
                    name={`${nuc}.moisture_count_2`}
                    onBlur={handleSubmit(submit(index))}
                  />
                </div>
              )}
              <div className="form-group col-12">
                <Field
                  required
                  component={renderField}
                  type="number"
                  label="Wet Density"
                  name={`${nuc}.wet_density`}
                  extraProps={{ step: "0.01" }}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>
              {isQ142A && (
                <div className="form-group col-12">
                  <Field
                    component={renderField}
                    type="number"
                    label="Wet Density 2"
                    name={`${nuc}.wet_density_2`}
                    extraProps={{ step: "0.01" }}
                    onBlur={handleSubmit(submit(index))}
                  />
                </div>
              )}
              <div className="form-group col-12">
                <Field
                  required
                  component={renderField}
                  type="number"
                  label="Moisture Percent"
                  name={`${nuc}.moisture_percent`}
                  extraProps={{ step: "0.01" }}
                  onBlur={handleSubmit(submit(index))}
                />
              </div>
              {isQ142A && (
                <div className="form-group col-12">
                  <Field
                    component={renderField}
                    type="number"
                    label="Moisture Percent 2"
                    name={`${nuc}.moisture_percent_2`}
                    extraProps={{ step: "0.01" }}
                    onBlur={handleSubmit(submit(index))}
                  />
                </div>
              )}
              <div className="form-group col-12">
                {!canUpdateStatus ? null : (
                  <Button
                    type="button"
                    onClick={() => {
                      const status = nucData.status == 5 ? 8 : 5;

                      const isComplete = requiredFields.every(
                        (field) =>
                          nucData[field] !== null &&
                          nucData[field] !== undefined &&
                          nucData[field] !== "",
                      );

                      if (!isComplete) {
                        return toast.error(
                          "Please complete all required fields before marking as complete",
                        );
                      }

                      updateStatus("update", `/tests/${nucData.test_uuid}`, {
                        status,
                      }).then(() => {
                        toast.success("Status Updated");
                        change(`${nuc}.status`, status);
                      });
                    }}
                    block
                    outline
                    color={nucData.status == 5 ? "danger" : "primary"}
                    disabled={updatingStatus}
                  >
                    {nucData.status == 5 ? "Unmark Complete" : "Mark Complete"}
                  </Button>
                )}
              </div>
              <div className="col-12">
                <small>Last updated by:</small>
                <p className="tx-inverse  mb-0">{nucData.activity.user}</p>
                <small>at:</small>
                <p className="tx-inverse ">{nucData.activity.created_at}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const copyPrevious = (nucForm: any[], index: number, change: Function) => {
  const previousNuc = _.pick(nucForm[index - 1], [
    "layer_depth",
    "test_depth",
    "standard_density_count",
    "standard_moisture_count",
    "equipment_id",
    "density_spec",
    "moisture_spec",
  ]);

  const newValues = nucForm.map((values, i) => {
    return i === index
      ? {
          ...values,
          ...previousNuc,
        }
      : values;
  });
  change("nucs", newValues);
};

export default FormNuclearDensometer;
