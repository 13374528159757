import { Link, useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useModal from "../hooks/useModal";
import BottomLogo from "../utils/BottomLogo";
import formError from "../utils/formError";
import InformationAlert from "../utils/InformationAlert";
import RenderField from "../utils/renderField";
import isSubmitting from "../utils/submitting";
import { useRedirectOnLogin } from "./hooks/useRedirectOnLogin";
import PromoModal from "./PromoModal";

const Login = (props) => {
  const { modal, toggle, setModal } = useModal();
  const { login } = useAuth();
  const history = useHistory();
  const redirect = useRedirectOnLogin();

  const loginAndSend = (values) => {
    values.device_id = localStorage.getItem("device_id");
    values.recent_organisation = localStorage.getItem("recent_organisation");

    return login(values)
      .then((user) => {
        if (user.two_factor_required) {
          history.push(
            `/two-factor-required?organisation=${user.two_factor_organisation}`,
          );
          return;
        }

        if (user.two_factor) {
          history.push("/two-factor-challenge");
          return;
        }

        if (!user.has_mfa && user.two_factor_required_at) {
          history.push("/two-factor-reminder");
          return;
        }

        redirect(user);
      })
      .catch(formError);
  };

  const { handleSubmit, submitting, error } = props;

  return (
    <div className="d-md-flex flex-row-reverse">
      <div className="signin-right flex-wrap signin-right-shadow">
        <div className="signin-box">
          <div className="w-100 d-flex justify-content-center mb-5">
            <a href="https://thebossapp.com.au">
              <img
                src="img/boss-app.png"
                alt="The BOSS App Logo"
                style={{ height: "75px" }}
              />
            </a>
          </div>
          <form onSubmit={handleSubmit(loginAndSend)}>
            {error && (
              <div className="mb-3">
                <InformationAlert
                  type="danger"
                  closable
                  body={error}
                  title="Error when Submitting Form"
                />
              </div>
            )}
            <div className="form-group">
              <Field
                name="email"
                component={RenderField}
                className="form-control"
                type="text"
                placeholder="Enter your E-mail"
                autoFocus
                tabIndex="1"
              />
            </div>
            <div className="form-group mg-b-20">
              <Field
                name="password"
                component={RenderField}
                type="password"
                className="form-control"
                placeholder="Enter your password"
              />
              <p className="mg-t-10">
                <Link to="/forgot">Forgot password?</Link>
              </p>
            </div>
            <button
              className="btn btn-primary w-100 btn-signin"
              disabled={submitting}
            >
              {isSubmitting(submitting, "Sign In", "Signing In...")}
            </button>
            <div className="d-flex align-items-end">
              {/* <div>
                <p className="mg-b-0">Get your organisation signed up! </p>
                <p className="mg-b-0">
                  <Link to="/register/organisation">Click here</Link>
                </p>
              </div> */}
              <button
                onClick={toggle}
                className="btn btn-link p-0 ms-auto"
                style={{ lineHeight: "1.5" }}
                type="button"
              >
                Learn More
              </button>
            </div>
          </form>
        </div>
      </div>

      <div
        className="position-absolute l-0 tx-20 b-0 w-100"
        style={{ zIndex: 3 }}
      >
        <div className="d-flex ms-2 justify-content-md-start justify-content-center">
          <a
            href="https://www.facebook.com/thebossapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-facebook" aria-hidden="true"></i>
          </a>
          <a
            className="ms-1"
            href="https://twitter.com/theBOSSapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-twitter" aria-hidden="true"></i>
          </a>
          <a
            className="ms-1"
            href="https://www.youtube.com/channel/UCr5hrEhNdCul19xYaBuH4KQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-youtube-play" aria-hidden="true"></i>
          </a>
          <a
            className="ms-1"
            href="https://www.linkedin.com/company/thebossapp/about"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-linkedin" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div className="signin-left d-none d-lg-flex flex-wrap align-items-stretch signin-brick position-relative paginated-card">
        <div
          className="w-100 d-flex justify-content-center twitter-shadow hide-scrollbar"
          style={{
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <a
            className="twitter-timeline shadow"
            data-width="400"
            data-height="100%"
            href="https://twitter.com/theBOSSapp?ref_src=twsrc%5Etfw"
          >
            Tweets by @theBOSSapp
          </a>{" "}
        </div>
        <BottomLogo />
      </div>
      <PromoModal toggle={toggle} modal={modal} setModal={setModal} />
    </div>
  );
};

const form = reduxForm({ form: "Login" });
export default form(Login);
