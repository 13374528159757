import { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Field, FieldArray, formValueSelector } from "redux-form";
import asyncOptions from "../../data/async_options";
import FieldType, {
  fieldTypes as defaultFieldTypes,
} from "../../enums/FieldType";
import ApprovalButton from "../approvals/ApprovalButton";
import FormulaField from "./FormulaField";
import buttonGroup from "../utils/buttonGroup";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import ChartField from "./ChartField";
import CustomFieldConditions from "./CustomFieldConditions";
import CustomFieldFilter from "./CustomFieldFilter";
import CustomFieldValue from "./CustomFieldValue";
import Options from "./Options";
import TextFieldOptions from "./TextFieldOptions";
import NotifyField from "./NotifyField";
import useApi from "../api/useApi";
import { Dropdown, DropdownItem, DropdownMenu } from "reactstrap";
import { useWindowEvent } from "../hooks/useWindowEvent";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiFillCopy } from "react-icons/ai";
import SelectInput from "../form/SelectInput";

const Fields = (props) => {
  const { fields, change, fieldTypes, customFields } = props;

  const [registerOptions, setRegisterOptions] = useState(asyncOptions);

  const { data: registers } = useApi(`registers`, []);
  const [contextMenu, setContextMenu] = useState({
    x: 0,
    y: 0,
    visible: false,
  });

  const [selectedConditional, setSelectedConditional] = useState();

  useWindowEvent("contextmenu", (e) => {
    e.preventDefault();
    setContextMenu({ x: e.pageX, y: e.pageY, visible: true });
  });

  useWindowEvent("click", (e) => {
    setContextMenu({ x: 0, y: 0, visible: false });
  });

  useEffect(() => {
    const databaseOptions = [...asyncOptions];

    registers.forEach((register) => {
      databaseOptions.push({
        label: register.name,
        value: `custom-fields/registers/${register.uuid}`,
      });
    });

    setRegisterOptions(databaseOptions);
  }, [registers]);

  const [newOrder, setNewOrder] = useState(null);
  const [codeSuggestions, setCodeSuggestions] = useState([]);

  const dragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    fields.move(source.index, destination.index);

    if (source.index !== destination.index) {
      setNewOrder([source.index, destination.index]);
    }
  };

  return (
    <>
      {contextMenu.visible && (
        <Dropdown
          isOpen={contextMenu.visible}
          style={{
            position: "absolute",
            top: contextMenu.y,
            left: contextMenu.x,
          }}
          toggle={() => setContextMenu({ ...contextMenu, visible: false })}
        >
          <DropdownMenu>
            <DropdownItem
              onClick={() =>
                navigator.clipboard.readText().then((text) => {
                  let uuidMapper = {};

                  try {
                    const parsed = JSON.parse(text);

                    const mapped = parsed.map((field) => {
                      const uuid = uuidv4();
                      uuidMapper[field.uuid] = uuid;

                      const newField = {
                        ...field,
                        field_attributes: {
                          ...field.field_attributes,
                          custom_fields:
                            field.field_attributes?.custom_fields?.map(
                              (field) => {
                                return {
                                  ...field,
                                  id: null,
                                  approval_action: null,
                                  uuid: uuidv4(),
                                };
                              },
                            ) ?? null,
                        },
                        id: null,
                        approval_action: null,
                        uuid: uuid,
                      };

                      return newField;
                    });

                    mapped.forEach(function (field) {
                      if (field.field_attributes?.conditionals) {
                        field.field_attributes.conditionals =
                          field.field_attributes.conditionals.map(
                            (conditional) => {
                              return {
                                ...conditional,
                                field: uuidMapper[conditional.field],
                              };
                            },
                          );
                      }

                      fields.push(field);
                    });
                  } catch (e) {
                    console.log(e);
                    toast.warning(
                      "Unable to paste clipboard data. please ensure you're using the 'copy fields' button.",
                    );
                  }
                })
              }
            >
              Paste Fields
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      <DragDropContext onDragEnd={dragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((field, index) => {
                const vals = fields.get(index);

                if (!vals?.uuid) {
                  change(`${field}.uuid`, uuidv4());
                }

                const fieldName =
                  vals?.field_attributes?.label ?? `Field ${index + 1}`;

                return (
                  <Draggable key={index} draggableId={field} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={`${
                          vals?.approval_action
                            ? "unapproved-custom-field"
                            : "bg-white"
                        } px-4 pb-2 pt-1 border form-group`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => {
                          if (
                            selectedConditional &&
                            selectedConditional.parent_field !== field
                          ) {
                            change(selectedConditional.field, vals.uuid);
                            setSelectedConditional(null);
                          }
                        }}
                      >
                        <div className="row">
                          <FieldFormHeader
                            approvalAction={vals?.approval_action}
                            fieldName={
                              vals?.type !== FieldType.Separator
                                ? fieldName
                                : "Line Separator"
                            }
                            onApproval={props.onApproval}
                            field={vals}
                          />
                          <div className="form-group col-lg-6">
                            <Field
                              name={`${field}.type`}
                              component={SelectInputAsync}
                              label="Type"
                              options={fieldTypes ?? defaultFieldTypes}
                              order={false}
                            />
                          </div>
                          {vals?.type !== FieldType.Separator && (
                            <>
                              <div className="form-group col-lg-6">
                                <Field
                                  name={`${field}.field_attributes.label`}
                                  component={RenderField}
                                  label="Name"
                                />
                              </div>
                              <div className="form-group col-lg-12">
                                <Field
                                  name={`${field}.field_attributes.information`}
                                  component={RenderField}
                                  label="Extra Information"
                                  textarea
                                />
                              </div>

                              <div className="form-group col-lg-3">
                                <Field
                                  name={`${field}.field_attributes.width`}
                                  component={RenderField}
                                  label="Width"
                                />
                              </div>

                              {vals?.type !== FieldType.Constant && (
                                <div className="form-group col-lg-2">
                                  <Field
                                    name={`${field}.field_attributes.required`}
                                    component={renderToggleInput}
                                    label="Required"
                                  />
                                </div>
                              )}

                              {vals?.type === FieldType.Constant && (
                                <div className="form-group col-lg-2">
                                  <Field
                                    name={`${field}.field_attributes.fsize`}
                                    component={RenderField}
                                    label="Font Size"
                                    information="Enter the desired font size for the label in pixels. Leave this field blank to use the default font size for the label."
                                  />
                                </div>
                              )}

                              <div className="form-group col-lg-2">
                                <Field
                                  name={`${field}.field_attributes.conditional`}
                                  component={renderToggleInput}
                                  label="Conditional"
                                />
                              </div>
                              {props.canSendExternally && (
                                <>
                                  <div className="form-group col-lg-2">
                                    <Field
                                      name={`${field}.field_attributes.disabled_externally`}
                                      label="Disable External"
                                      component={renderToggleInput}
                                    />
                                  </div>
                                  <div className="form-group col-lg-2">
                                    <Field
                                      name={`${field}.field_attributes.hide_externally`}
                                      label="Hide External"
                                      component={renderToggleInput}
                                    />
                                  </div>
                                </>
                              )}
                              {vals?.type === FieldType.Constant && (
                                <div className="form-group col-12">
                                  <Field
                                    component={RenderField}
                                    textarea
                                    name={`${field}.field_attributes.text`}
                                    label="Text"
                                  />
                                </div>
                              )}
                              <div className="col-lg-2 d-flex align-items-end form-group">
                                <Button
                                  color="primary"
                                  outline
                                  onClick={() =>
                                    fields.insert(index + 1, {
                                      uuid: uuidv4(),
                                    })
                                  }
                                >
                                  Add Below
                                </Button>
                              </div>
                              <div className="col-lg-1 d-flex align-items-end form-group">
                                <Button
                                  color="primary"
                                  outline
                                  onClick={() => {
                                    return fields.insert(index + 1, {
                                      ...vals,
                                      approval_action: null,
                                      id: null,
                                      uuid: uuidv4(),
                                      field_attributes: {
                                        ...vals.field_attributes,
                                        label:
                                          vals.field_attributes?.label.concat(
                                            " - duplicate",
                                          ),
                                      },
                                    });
                                  }}
                                >
                                  Duplicate
                                </Button>
                              </div>
                              <div
                                className={`${
                                  vals?.type === FieldType.Constant
                                    ? "col-lg-12"
                                    : "col-lg-2"
                                } form-group d-flex align-items-end justify-content-end`}
                              >
                                <Button
                                  color="danger"
                                  outline
                                  onClick={() => fields.remove(index)}
                                >
                                  Delete
                                </Button>
                              </div>

                              {[
                                FieldType.Date,
                                FieldType.Text,
                                FieldType.Time,
                                FieldType.RadioButton,
                                FieldType.Toggle,
                              ].includes(parseInt(vals?.type)) && (
                                <div className="form-group col-lg-2">
                                  <Field
                                    name={`${field}.field_attributes.notify`}
                                    component={renderToggleInput}
                                    label="Notify"
                                  />
                                </div>
                              )}
                              <TextFieldOptions value={vals} field={field} />

                              <NotifyField field={field} value={vals} />
                              {vals?.type === 16 && (
                                <>
                                  <div className="col-12 form-group">
                                    <Field
                                      name={`${field}.field_attributes.prompt_assistant_id`}
                                      component={SelectInput}
                                      label="Prompt Assistant"
                                      url="open-ai/assistants"
                                      formatData={(data) =>
                                        data.map((d) => ({
                                          label: d.name ?? "Untitled Assistant",
                                          value: d.id,
                                        }))
                                      }
                                    />
                                  </div>
                                  <div className="col-12 form-group">
                                    <Field
                                      name={`${field}.field_attributes.prompt`}
                                      component={RenderField}
                                      label="Prompt"
                                      textarea
                                    />
                                  </div>
                                </>
                              )}
                              {vals?.type === FieldType.Date && (
                                <>
                                  <FormHeader>
                                    {fieldName} Date Format
                                  </FormHeader>
                                  <div className="form-group col-12">
                                    <Field
                                      component={RenderField}
                                      name={`${field}.field_attributes.date_format`}
                                      label="Date Format"
                                    />
                                    <small>
                                      Please see{" "}
                                      <a
                                        href="/date-format"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        here
                                      </a>{" "}
                                      for formatting options (leave blank for
                                      <i className="ms-1">d/m/Y</i>). This
                                      format applies only on generated files
                                      from template.
                                    </small>
                                  </div>
                                </>
                              )}
                              {vals?.type === FieldType.FieldArray && (
                                <div className="form-group col-12">
                                  <FieldArray
                                    name={`${field}.field_attributes.custom_fields`}
                                    label="Formula"
                                    fieldTypes={defaultFieldTypes.filter(
                                      (type) =>
                                        ![
                                          FieldType.Chart,
                                          FieldType.Formula,
                                          FieldType.CustomValue,
                                          FieldType.FieldArray,
                                        ].includes(type.value),
                                    )}
                                    component={Fields}
                                    index={index}
                                    change={change}
                                    getExtraFilters={props.getExtraFilters}
                                  />
                                </div>
                              )}
                              {vals?.type === FieldType.Formula && (
                                <>
                                  <div className="form-group col-lg-12">
                                    <Field
                                      name={`${field}.field_attributes.formula`}
                                      label={
                                        <>
                                          <p className="mb-0">Formula</p>
                                          <Field
                                            component={buttonGroup}
                                            buttonClass="btn-sm"
                                            required
                                            validate={required}
                                            size="sm"
                                            name={`${field}.field_attributes.formula_type`}
                                            options={[
                                              {
                                                label: "Math",
                                                value: "math",
                                              },
                                              {
                                                label: "Code",
                                                value: "code",
                                              },
                                            ]}
                                          />
                                        </>
                                      }
                                      component={FormulaField}
                                      fields={props.customFields}
                                      vals={vals}
                                      codeSuggestions={codeSuggestions}
                                      setCodeSuggestions={setCodeSuggestions}
                                    />
                                  </div>
                                </>
                              )}

                              {vals?.type === FieldType.Chart && (
                                <ChartField
                                  vals={vals}
                                  field={field}
                                  fields={props.customFields}
                                  parentIndex={index}
                                  codeSuggestions={codeSuggestions}
                                  setCodeSuggestions={setCodeSuggestions}
                                />
                              )}
                              {[
                                FieldType.Select,
                                FieldType.RadioButton,
                              ].includes(vals?.type) && (
                                <>
                                  <FormHeader>
                                    {fieldName}{" "}
                                    {vals?.type === FieldType.Select
                                      ? "Select"
                                      : "Radio Button"}{" "}
                                    Attributes
                                  </FormHeader>
                                  {vals?.type === FieldType.Select && (
                                    <>
                                      <div className="form-group col-lg-6">
                                        <Field
                                          name={`${field}.field_attributes.multiple`}
                                          component={renderToggleInput}
                                          label="Can Select Multiple"
                                        />
                                      </div>
                                      <div className={`form-group col-lg-6`}>
                                        <Field
                                          name={`${field}.field_attributes.options_selection`}
                                          component={buttonGroup}
                                          groupClass="w-100"
                                          buttonClass="w-100"
                                          onChange={(val) => {
                                            if (!val) {
                                              return;
                                            }
                                            if (val == 0) {
                                              change(
                                                `${field}.field_attributes.url`,
                                                "",
                                              );
                                            }

                                            change(
                                              `${field}.field_attributes.options`,
                                              "",
                                            );
                                          }}
                                          options={[
                                            {
                                              label: "Custom Options",
                                              value: 0,
                                            },
                                            {
                                              label: "From Database",
                                              value: 1,
                                            },
                                          ]}
                                          label="Options"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {(vals.field_attributes?.options_selection ===
                                    0 ||
                                    vals?.type === FieldType.RadioButton) && (
                                    <FieldArray
                                      component={Options}
                                      name={`${field}.field_attributes.options`}
                                    />
                                  )}
                                  {vals.field_attributes?.options_selection ===
                                    1 &&
                                    vals?.type === FieldType.Select && (
                                      <>
                                        <div className="col-lg-12 form-group">
                                          <Field
                                            component={SelectInputAsync}
                                            options={registerOptions}
                                            name={`${field}.field_attributes.url`}
                                            label="Options"
                                          />
                                        </div>
                                        <FieldArray
                                          component={CustomFieldFilter}
                                          name={`${field}.field_attributes.filters`}
                                          url={vals?.field_attributes?.url}
                                          index={index}
                                          form={props.form}
                                          change={change}
                                          field={field}
                                          getExtraFilters={
                                            props.getExtraFilters
                                          }
                                        />
                                      </>
                                    )}
                                </>
                              )}

                              {vals?.type === FieldType.CustomValue && (
                                <CustomFieldValue
                                  field={field}
                                  customFields={props.customFields}
                                  value={vals}
                                  newOrder={newOrder}
                                  change={change}
                                />
                              )}
                              {vals?.field_attributes?.conditional && (
                                <FieldArray
                                  component={CustomFieldConditions}
                                  setSelectedConditional={
                                    setSelectedConditional
                                  }
                                  name={`${field}.field_attributes.conditionals`}
                                  index={index}
                                  form={props.form}
                                  change={change}
                                  field={field}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="row">
        <div className="col-12">
          <Button
            onClick={() =>
              fields.push({
                uuid: uuidv4(),
              })
            }
            color="secondary"
          >
            Add New Field
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    customFields: selector(state, "custom_fields"),
  };
};

const FieldFormHeader = ({ fieldName, approvalAction, onApproval, field }) => {
  return (
    <div className="col-12">
      <div className="d-flex align-items-center mt-3">
        <label className="section-title my-0">{fieldName}</label>
        <div className="ms-auto space-x-3 d-flex align-items-center">
          <CopyToClipboard
            text={JSON.stringify([field])}
            options={{ format: "text/plain" }}
            onCopy={() => toast.success(`${fieldName} copied.`)}
          >
            <span className="pointer-hover">
              <AiFillCopy />
            </span>
          </CopyToClipboard>
          <ApprovalButton
            approvalAction={approvalAction}
            buttonClass="p-1 btn btn-outline-secondary"
            wrapperClass=""
            onSubmitted={onApproval}
          />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default connect(mapStateToProps, {})(Fields);
