import { useMemo } from "react";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import useRequiredCompetencies from "./hooks/useRequiredCompetencies";
import ReactTable from "../table/ReactTable";
import { Button } from "reactstrap";
import useModal from "../hooks/useModal";
import { RiAddFill } from "react-icons/ri";
import FormModal from "./FormModal";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import formError from "../utils/formError";
import { useQueryClient } from "react-query";
import { IUseApi } from "../api/apiTypes";

interface RequiredCompetency {
  uuid: string;
  skill_level: {
    name: string;
    skill_name: string;
  };
  requirable: {
    id: number;
    name: string;
    type: string;
    link: string;
  };
}

const requirableTypeFilters = [
  {
    label: "Type",
    name: "requirable_type",
    options: [
      {
        label: "Role",
        value: encodeURIComponent("App\\Models\\OrganisationRole"),
      },
      {
        label: "Position",
        value: encodeURIComponent("App\\Models\\OrganisationPosition"),
      },
      { label: "Default", value: "default" },
    ],
  },
];

const RequiredCompetenciesList = () => {
  const { modal, toggle } = useModal();
  const { takeAction }: IUseApi = useApi();

  const queryClient = useQueryClient();

  const submit = (values: any, _: any, props: any) => {
    return takeAction("store", "required-competencies", values)
      .then(({ data }) => {
        toggle();
        toast.success(`${data.data.skill_level.name} added.`);
        queryClient.invalidateQueries(["required-competencies"]);

        props.reset?.();
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Required Competencies" />
      <PaginatedList
        indexHook={useRequiredCompetencies}
        listName="requiredCompetencies"
        extraButtons={() => (
          <Button onClick={toggle} className="p-0 ms-1" color="link">
            <RiAddFill aria-label="Add New Resource" className="tx-24 ms-1" />
          </Button>
        )}
        originalFilters={requirableTypeFilters}
        list={({ data }) => {
          const requiredCompetencies = data as RequiredCompetency[];

          return (
            <div className="col-12">
              <RequiredCompetencyTable
                requiredCompetencies={requiredCompetencies}
              />
            </div>
          );
        }}
      />
      <FormModal
        modal={modal}
        toggle={toggle}
        onSubmit={submit}
        showWarning={true}
      />
    </>
  );
};

const RequiredCompetencyTable = ({
  requiredCompetencies,
}: {
  requiredCompetencies: RequiredCompetency[];
}) => {
  const { takeAction } = useApi();

  const columnHelper = createColumnHelper<RequiredCompetency>();

  const queryClient = useQueryClient();

  const columns = useMemo(
    () => [
      columnHelper.accessor("requirable", {
        header: "Type",
        cell: (info) => {
          const requirable = info.getValue();

          if (!requirable) {
            return <p className="text-dark mb-0">Default</p>;
          }

          return (
            <div>
              <Link to={requirable.link} className="text-dark mb-1">
                {requirable.name}
              </Link>
              <p className="text-muted mb-0">{requirable.type}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("skill_level", {
        header: "Skill",
        cell: (info) => {
          const skillLevel = info.getValue();

          return (
            <div>
              <p className="text-dark mb-1">{skillLevel.skill_name}</p>
              <p className="text-muted mb-0">{skillLevel.name}</p>
            </div>
          );
        },
      }),

      columnHelper.display({
        id: "uuid",
        header: "Delete",
        cell: (info: any) => {
          return (
            <button
              type="button"
              onClick={() => {
                const competency = info.row.original;
                deleteSwal(competency.skill_level.name)
                  .then(() =>
                    takeAction(
                      "destroy",
                      `required-competencies/${competency.uuid}`,
                    ),
                  )
                  .then(() => {
                    toast.success(`${competency.skill_level.name} deleted.`);
                    queryClient.invalidateQueries(["required-competencies"]);
                  })
                  .catch(errorSwal);
              }}
              className="btn btn-outline-danger btn-sm"
            >
              Delete
            </button>
          );
        },
      }),
    ],
    [],
  ) as ColumnDef<RequiredCompetency>[];

  return (
    <ReactTable columns={columns} data={requiredCompetencies} disableSearch />
  );
};

export default RequiredCompetenciesList;
