import { useAuth } from "../../context/auth-context";
import { useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/api";
import errorSwal from "../utils/errorSwal";
import * as Sentry from "@sentry/react";
import { Organisation } from "./types";
import { useState } from "react";
import Spinner from "../utils/Spinner";

const UserRecentOrganisation = ({
  recentOrganisations,
}: {
  recentOrganisations: Organisation[];
}) => {
  const { setUser, user } = useAuth();
  const queryData = useQueryClient();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const selectOrganisation = (organisation: Organisation) => {
    setLoading(true);

    return axios
      .post(`users/organisations/active`, {
        id: organisation.id,
      })
      .then(({ data }) => {
        toast.success(`Organisation changed to ${organisation.display_name}`);
        setUser(data.data);
        localStorage.setItem("recent_organisation", data.data.active_organisation.uuid);

        queryData.resetQueries();
        if (!import.meta.env.DEV) {
          Sentry.setContext("organisation", {
            name: data.data.active_organisation.display_name,
          });
        }

        history.push("/home");
        history.go(0);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorSwal(error);
      });
  };

  return (
    <>
      <Spinner loading={loading} />
      {recentOrganisations.map((org: Organisation, index: any) => (
        <Link
          key={index}
          to=""
          tabIndex={(index + 1).toString()}
          className="nav-link"
          onClick={(e) => {
            e.preventDefault();
            selectOrganisation(org);
          }}
        >
          {org.display_name}
        </Link>
      ))}
    </>
  );
};
export default UserRecentOrganisation;
