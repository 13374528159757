import { useState } from "react";
import { Field } from "redux-form";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";

const Fields = () => {
  const [skillCategorySkills, setSkillCategorySkills] = useState([]);
  const [skillLevels, setSkillLevels] = useState([]);
  const { takeAction } = useApi();

  const handleSkillCategoryChange = (_, value) => {
    setSkillCategorySkills([]);

    takeAction("index", `skill-categories/${value}/competency-skills`).then(
      ({ data }) =>
        setSkillCategorySkills(
          data.data.map((d) => ({
            label: d.skill,
            value: d.id,
            skillLevels: d.skill_levels,
          })),
        ),
    );
  };

  const handleSkillChange = (v, value) => {
    const skillCategory = skillCategorySkills.find((s) => s.value === value);

    setSkillLevels(
      skillCategory.skillLevels.map((level) => ({
        label: level.name,
        value: level.id,
      })),
    );
  };

  return (
    <div className="row">
      <div className="col-lg-12 form-group">
        <Field
          component={SelectInput}
          url="/skill-categories"
          name="skill_category"
          label="Category"
          formatData={formatSkillCategories}
          onChange={handleSkillCategoryChange}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          component={SelectInput}
          options={skillCategorySkills}
          name="skill"
          label="Competency Skill"
          onChange={handleSkillChange}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          component={SelectInput}
          options={skillLevels}
          name="skill_level_id"
          label="Skill Level"
        />
      </div>
    </div>
  );
};

const formatSkillCategories = (data) => {
  return data.map((skillCategory) => {
    return {
      label: skillCategory.name,
      value: skillCategory.uuid,
    };
  });
};

export default Fields;
