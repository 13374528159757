import { ChartData } from "chart.js";
import * as d3 from "d3";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import { primary } from "../utils/Colours";
import ReactTable from "../table/ReactTable";
import { Link } from "react-router-dom";
import TextButton from "../utils/TextButton";
import { useEffect, useMemo, useState } from "react";

type Status =
  | "Current"
  | "19mm Sieved"
  | "Prepped"
  | "Tested"
  | "Completed"
  | "Void"
  | "Delivered"
  | "Sampled"
  | "Compaction Complete"
  | "Ready to be Placed"
  | "Penetrated"
  | "ATT Prep"
  | "Moistures"
  | "Placed"
  | "Wash Fines"
  | "PSD Fines"
  | "Change Name"
  | "In Transit"
  | "Ready to be Reported"
  | "Reported"
  | "Prepped from PSD"
  | "Results Reviewed"
  | "Received"
  | "Sieved"
  | "Weighing up Points"
  | "Soaking Started"
  | "Dry Points Tested"
  | "Wash/Dry Required Fractions"
  | "Sieve and Weigh"
  | "Wash/Dry Retained 1.70mm Sieve"
  | "Soaking Cycle 1"
  | "Drying Cycle 1"
  | "Soaking Cycle 2"
  | "Drying Cycle 2"
  | "Soaking Cycle 3"
  | "Drying Cycle 3"
  | "Soaking Cycle 4"
  | "Drying Cycle 4"
  | "Soaking Cycle 5"
  | "Drying Cycle 5"
  | "Wash and Dry"
  | "Prepped - Air Dried"
  | "Test Coarse and Intermediate Fractions"
  | "Test Fine Fractions"
  | "Review Compaction Results"
  | "CBR Placed"
  | "Weight Retained 0.075mm Sieve"
  | "Tumble Fractions"
  | "Cured"
  | "Soak"
  | "Soaked"
  | "Test"
  | "Weight Dried Retained 1.70mm Sieve"
  | "Wet Points Tested";

interface GroupedData {
  items: TestProductivity[];
  from: Status;
  to: Status;
}

interface GroupHeader {
  id: string;
  isGroupHeader: true;
  test: {
    method: string;
    name: string;
  };
  from: Status;
  to: Status;
  count: number;
}

interface TestProductivity {
  from: Status;
  to: Status;
  test: {
    name: string;
    test_method_id: number;
    method: string;
  };
  created_at: string;
  test_id: number;
}

const TestProductivity = ({
  productivity,
  timesheet,
}: {
  productivity: TestProductivity[];
  timesheet: {
    start_time: string;
    finish_time: string;
  };
}) => {
  const defaultColumns = [
    {
      accessorKey: "test",
      header: "Test",
      cell: (info: any) => {
        const test = info.getValue();

        return (
          <Link to={test.link}>
            <p className="mb-0 tx-12 text-muted">{test.method}</p>
            <p className="mb-0 text-dark">{test.name}</p>
          </Link>
        );
      },
    },
    {
      accessorKey: "from",
      header: "From",
    },
    {
      accessorKey: "to",
      header: "To",
    },
    {
      accessorKey: "created_at",
      header: "Time",
      cell: (info: any) => dayjs(info.getValue()).format("hh:mm a"),
    },
    {
      accessorKey: "expected_time",
      header: "Expected Time (mins)",
    },
  ];

  function getHoursBetween(start: string, end: string) {
    const startTime = dayjs(`2023-01-01T${start}`).startOf("hour").toDate();
    const endTime = dayjs(`2023-01-01T${end}`)
      .endOf("hour")
      .add(1, "minute")
      .toDate();
    let hours = [];

    console.log(endTime);

    // Using D3's timeHour to increment hours
    for (
      let time = startTime;
      time <= endTime;
      time = d3.timeHour.offset(time, 1)
    ) {
      hours.push(d3.timeFormat("%H:00")(time));
    }

    return hours;
  }

  const hours = getHoursBetween(timesheet.start_time, timesheet.finish_time);

  const mapData = hours.map((hour) => {
    const test = productivity.filter(
      (test) => dayjs(test.created_at).format("HH:00") === hour,
    );

    if (!test) {
      return 0;
    }

    return test.length;
  });

  const data: ChartData<"line"> = {
    labels: hours,
    datasets: [
      {
        label: "Test Status Changed",
        data: mapData,
        borderColor: primary,
        backgroundColor: primary,
      },
    ],
  };

  const [groupBy, setGroupBy] = useState(false);
  const [columns, setColumns] = useState(defaultColumns);

  const groupedData = useMemo(() => {
    if (!groupBy) return productivity;

    return productivity.reduce<Record<string, GroupedData>>((groups, item) => {
      const key = `${item.test.method}|${item.from}|${item.to}`;

      if (!groups[key]) {
        groups[key] = {
          items: [],
          from: item.from,
          to: item.to,
        };
      }
      groups[key].items.push(item);
      return groups;
    }, {});
  }, [productivity, groupBy]);

  const processedData = useMemo(() => {
    if (!groupBy) return productivity;

    return Object.entries(groupedData).map(
      ([key, group]): GroupHeader => ({
        id: `group-${key}`,
        isGroupHeader: true,
        test: {
          method: group.items[0].test.method,
          name: group.items[0].test.name,
        },
        from: group.from,
        to: group.to,
        count: group.items.length,
      }),
    );
  }, [groupedData, groupBy]);

  useEffect(() => {
    if (!groupBy) {
      setColumns(defaultColumns);
      return;
    }

    const groupedColumns = [
      {
        accessorKey: "test",
        header: "Test",
        cell: (info: any) => {
          const test = info.getValue();
          return (
            <>
              <p className="mb-0 tx-12 text-muted">{test.method}</p>
              <p className="mb-0 text-dark">{test.name}</p>
            </>
          );
        },
      },
      ...defaultColumns.filter(
        (col) =>
          !["created_at", "expected_time", "test"].includes(col.accessorKey),
      ),
      {
        accessorKey: "count",
        header: "Quantity",
        cell: (info: any) => info.row.original.count ?? 1,
      },
    ];

    setColumns(groupedColumns);
  }, [groupBy]);

  return (
    <>
      <div className="row mb-5">
        <div className="col-lg-8">
          <div className="bg-white p-3 rounded-lg shadow-sm border">
            <Line data={data} />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="p-3 bg-white rounded-lg shadow-sm border mb-3">
            <p className="tx-18 fw-bolder text-dark mb-0">
              {productivity.length}
            </p>
            <p className="mb-0">Total status changes</p>
          </div>
          <div className="p-3 bg-white rounded-lg shadow-sm border mb-3">
            <p className="tx-18 fw-bolder text-dark mb-0">
              {productivity.filter((p) => p.to === "Completed").length}
            </p>
            <p className="mb-0">Total Tests Completed</p>
          </div>
        </div>
      </div>
      <ReactTable
        columns={columns}
        data={processedData as any}
        extraButtons={
          <TextButton
            className={`text-secondary mg-l-20 ${groupBy ? "active" : ""}`}
            onClick={() => setGroupBy(!groupBy)}
          >
            {groupBy ? "Ungroup" : "Group By Test"}
          </TextButton>
        }
      />
    </>
  );
};

export default TestProductivity;
