import dayjs from "dayjs";
import { DraggableProvided, DraggableStateSnapshot } from "@hello-pangea/dnd";
import { AiOutlineWarning } from "react-icons/ai";
import { FiMoreHorizontal } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  PopoverBody,
  PopoverHeader,
  UncontrolledDropdown,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";
import { Field } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { date } from "../form/formatters";
import useModal from "../hooks/useModal";
import {
  StatusBubble,
  statusDetails,
} from "../projectManagers/ProjectManagerJob";
import { findAndReplace } from "../utils/arrayUtils";
import BottomLogo from "../utils/BottomLogo";
import dateField from "../utils/dateTime";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";

interface DraggableJobProps {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  job: any;
}

const DraggableJob = ({ job, provided, snapshot }: DraggableJobProps) => {
  const statusInfo = statusDetails(job);

  const jobWontBeCompleteBeforeScheduled = dayjs(
    job.scheduled_finish_date,
  ).isBefore(dayjs(job.expected_finish_date).format("YYYY-MM-DD"));

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div
        className={`border p-3 rounded-lg position-relative tn-300 ${
          snapshot.isDragging ? "shadow bg-gray-200" : "shadow-sm bg-white"
        }`}
        style={{ zIndex: 100 }}
      >
        <div className="d-flex">
          <div className="width-25">
            <div
              id={`job_status_${job.uuid}`}
              className={`me-2 d-flex justify-content-center align-items-center rounded-circle shadow bg-grad-${statusInfo.color}`}
              style={{
                height: "40px",
                width: "40px",
              }}
            >
              <StatusBubble job={job} />
              <UncontrolledTooltip target={`job_status_${job.uuid}`}>
                {statusInfo.text}
              </UncontrolledTooltip>
            </div>
          </div>
          <div className="text-justify no-wrap">
            <Link
              id={`job_${job.uuid}`}
              to={job.link}
              className="mb-2 tx-inverse fw-bold"
            >
              {job.name}
              <p className="mb-0 text-secondary fw-normal tx-12">
                {job.number}
              </p>
            </Link>
          </div>
          <div className="ms-auto space-x-2 d-flex">
            <Button
              id={`job_description_${job.uuid}`}
              type="button"
              color="link"
              className="p-0 ms-1"
              style={{ height: "14px" }}
            >
              <i className="fa fa-info-circle tx-inverse" />
            </Button>
            <UncontrolledPopover
              placement="auto"
              target={`job_description_${job.uuid}`}
            >
              <PopoverHeader>{job.name} Description</PopoverHeader>
              <PopoverBody className="white-space">
                {job.description}
              </PopoverBody>
            </UncontrolledPopover>
            <UncontrolledTooltip
              placement="top-start"
              target={`job_${job.uuid}`}
            >
              {job.name}
            </UncontrolledTooltip>
            {/* <JobOptions job={job} setJobs={setJobs} jobs={jobs} /> */}
          </div>
        </div>
        <div className="mt-5 space-y-3">
          <Link to={`/${job.project.link}`} className="mb-0 text-secondary">
            {job.project.full_name}
          </Link>
          <p className="mb-0 text-secondary tx-12">
            Scheduled to be finished on{" "}
            <span className="tx-inverse">
              {dayjs(job.scheduled_finish_date).format("DD/MM/YYYY")}
            </span>
            {job.expected_finish_date && (
              <>
                {" "}
                - Expected to be finished on{" "}
                <span
                  className={`${
                    jobWontBeCompleteBeforeScheduled
                      ? "text-warning"
                      : "tx-inverse"
                  }`}
                >
                  {dayjs(job.expected_finish_date).format("DD/MM/YYYY")}
                </span>
                {jobWontBeCompleteBeforeScheduled && (
                  <AiOutlineWarning
                    className="text-warning tx-16"
                    style={{ marginBottom: "6px" }}
                  />
                )}
              </>
            )}
          </p>
        </div>
        <BottomLogo size={30} bottom={25} left={235} />
      </div>
    </div>
  );
};

export const JobOptions = ({
  job,
  jobs,
  setJobs,
}: {
  job: any;
  jobs: any[];
  setJobs: Function;
}) => {
  const { modal, toggle } = useModal();
  const { takeAction }: IUseApi = useApi();

  const onSubmit = (values: any) => {
    return takeAction("update", `project-jobs/${job.uuid}`, values)
      .then(({ data }) => {
        setJobs(findAndReplace("uuid", jobs, data.data));
        toggle();
      })
      .catch(errorSwal);
  };
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          style={{ height: "20px" }}
          className="p-0"
          size="sm"
          color="link"
        >
          <div className={"text-secondary"}>
            <FiMoreHorizontal className="tx-16" />
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={toggle}>
            Edit Scheduled Finish Date
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <EditScheduled
        job={job}
        modal={modal}
        toggle={toggle}
        onSubmit={onSubmit}
      />
    </>
  );
};

const EditScheduled = ({
  modal,
  toggle,
  job,
  onSubmit,
}: {
  modal: boolean;
  toggle: Function;
  job: any;
  onSubmit: Function;
}) => {
  return (
    <FormModal
      title={`Edit Scheduled Finish Date for ${job.name}`}
      toggle={toggle}
      modal={modal}
      onSubmit={onSubmit}
      initialValues={job}
      form="EditJobDate"
    >
      <div className="col-12">
        <Field
          component={dateField}
          name="scheduled_finish_date"
          label="Scheduled Finish Date"
          {...date}
        />
      </div>
    </FormModal>
  );
};

export default DraggableJob;
