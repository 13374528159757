import React from "react";
import Select from "react-select";
import { WrappedFieldProps } from "redux-form";
import { SelectOption } from "../utils/utilTypes";

interface InstantSearchSelectInputProps {
  label: React.ReactNode;
  required?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  empty?: React.ReactNode;
  changeValue?: (value: SelectOption) => void;
  hits: any[];
  formatOption?: (hit: any) => SelectOption;
  query: string;
  refine: (value: string) => void;
  loadMore?: () => void;
  hasMore?: boolean;
}

const InstantSearchSelectInput = (
  props: WrappedFieldProps & InstantSearchSelectInputProps,
) => {
  const {
    input,
    label,
    required,
    isMulti,
    isDisabled,
    isLoading,
    empty,
    meta: { touched, error, warning },
    changeValue,
    hits,
    formatOption = (hit) => ({
      label: hit.number || hit.name,
      value: hit.id,
    }),
    refine,
    loadMore,
    hasMore = false,
  } = props;

  const options = hits.map(formatOption);

  const value = isMulti
    ? input.value
    : options.find((option) => input.value === option.value);

  return (
    <div>
      <div className="d-flex space-x-1">
        {label && (
          <>
            <label className="form-control-label tx-inverse tx-semibold">
              {label}
            </label>
            {required ? <span className="tx-danger"> *</span> : ""}
          </>
        )}
      </div>
      <Select
        isClearable
        isMulti={isMulti}
        options={options}
        value={value}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onChange={(value: any, { action }) => {
          changeValue?.(value);

          if (action === "clear") {
            input.onChange(null);
          } else {
            input.onChange(isMulti ? value : value.value);
          }
        }}
        onInputChange={(newValue) => {
          refine(newValue);
          return newValue;
        }}
        // @ts-ignore
        onBlur={(event: React.FocusEvent) => props.input.onBlur()}
        className="shadow-sm"
        onMenuScrollToBottom={() => {
          if (hasMore && loadMore) {
            loadMore();
          }
        }}
      />
      {!isLoading && options.length === 0 && (
        <small className="d-block">{empty}</small>
      )}
      {touched && warning && <span className="tx-warning">{warning}</span>}
      {touched && error && <span className="parsley-errors-list">{error}</span>}
    </div>
  );
};

export default InstantSearchSelectInput;
