import _ from "lodash";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import ModalButton from "../noticeboards/ModalButton";
import Notices from "../noticeboards/Notices";
import { AdministrationLevels } from "../user/userTypes";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import Card from "./Card";
import HomeData from "./HomeData";
import InvoiceReminder from "./InvoiceReminder";
import ReminderModal from "./ReminderModal";
import StaffRoleReminder from "./StaffRoleReminder";
import TimesheetReminder from "./TimesheetReminder";
import SubjectItemReminder from "./SubjectItemReminder";
import ProductivityCards from "./ProductivityCards";

const Home = () => {
  const { takeAction } = useApi();
  const [activeDashboard, setActiveDashboard] = useState("");
  const [dashboardData, setDashboardData] = useState([]);
  const [fetchingDashboardData, setFetchingDashboardData] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [reminder, setReminder] = useState();
  const { user } = useAuth();

  const [activeLink, setActiveLink] = useState();

  const showOverview = useOrganisationSetting("show_user_overview");

  const isBasic =
    user.position_administration_level == AdministrationLevels.Basic &&
    !user.is_admin;
  const {
    data: homeDetails,
    takeAction: reminderAction,
    refreshData,
  } = useApi("home", []);

  useEffect(() => {
    if (activeDashboard) {
      setDashboardData([]);
      setFetchingDashboardData(true);

      if (activeDashboard.description === "Jobs") {
        setActiveLink(activeDashboard.link);
        setFetchingDashboardData(false);
      } else {
        takeAction("index", activeDashboard.link)
          .then(({ data }) => {
            setDashboardData(data.data);
          })
          .catch(errorSwal)
          .then(() => setFetchingDashboardData(false));
      }
    }
  }, [activeDashboard]);

  const currentReminder = _.flatMap(
    homeDetails,
    ({ details }) => details,
  ).filter((d) => d.value > 0 && Number.isInteger(d.type))?.[modalIndex];

  useEffect(() => {
    if (currentReminder) {
      setReminder();
      reminderAction(
        "index",
        `${currentReminder.link}${currentReminder.update_details ?? ""}`,
      ).then(({ data }) => {
        if (data.data.length === 0) {
          setModalIndex(modalIndex + 1);
          return;
        }

        setReminder(data.data);
      });
      return;
    }

    setReminder();
  }, [modalIndex, homeDetails]);

  return (
    <>
      <HeaderPage titlePage="Home" crumbs={[{}]} />
      <TimesheetReminder />
      <InvoiceReminder />
      <div className="mb-3 d-flex align-items-center">
        <div>
          <h4 className="tx-inverse mg-b-3">Your Work Summary</h4>
          <p className="mg-b-0">Overall Work to be done.</p>
        </div>
        <div className="ms-auto">
          {activeDashboard && (
            <button
              type="button"
              className="btn btn-link"
              onClick={() => setActiveDashboard("")}
              disabled={fetchingDashboardData}
            >
              Show Noticeboard
            </button>
          )}
        </div>
      </div>
      {_.chunk(homeDetails, 4).map((details, index) => {
        return (
          <div
            key={index}
            className="no-gutters dashboard-chart-one mb-0 mb-sm-0"
          >
            <div className="row">
              {details.map((d, index) => {
                return (
                  <Card
                    key={index}
                    {...d}
                    activeDashboard={activeDashboard}
                    setActiveDashboard={setActiveDashboard}
                    setFetchingDashboardData={setFetchingDashboardData}
                    fetchingDashboardData={fetchingDashboardData}
                  />
                );
              })}
            </div>
          </div>
        );
      })}

      {activeDashboard && (
        <div className="d-none d-lg-block mb-3">
          {fetchingDashboardData ? (
            <CustomScaleLoader>Fetching data...</CustomScaleLoader>
          ) : (
            <HomeData
              activeDashboard={activeDashboard}
              dashboardData={dashboardData}
              setDashboardData={setDashboardData}
              refreshData={refreshData}
              userUuid={user.uuid}
            />
          )}
        </div>
      )}
      {isBasic || !showOverview?.meta_value ? null : (
        <div className="mg-b-35">
          <ProductivityCards overviewWidth={4} />
        </div>
      )}
      {!activeDashboard && (
        <>
          <hr />
          <div className="mb-3 d-flex align-items-center">
            <div>
              <h4 className="tx-inverse mg-b-3">Noticeboard</h4>
              <p className="mg-b-0">See Updates From Your Colleagues.</p>
            </div>
            <div className="ms-auto">
              <ModalButton />
            </div>
          </div>
          <Notices />
        </>
      )}

      <ReminderModal
        modal={reminder && reminder?.length > 0}
        reminder={reminder}
        currentReminderType={currentReminder}
        setReminder={setReminder}
        modalIndex={modalIndex}
        setModalIndex={setModalIndex}
      />
      <StaffRoleReminder />
      <SubjectItemReminder />
    </>
  );
};

export default Home;
