import AddModalButton from "./AddModalButton";
import SkillTable from "./SkillTable";
import { useParams } from "react-router-dom";
import PaginatedList from "../pagination/PaginatedList";
import useCompetencySkills, {
  baseCompetencySkillFilters,
} from "./hooks/useCompetencySkills";

const List = (props) => {
  const { uuid } = useParams();

  return (
    <>
      <AddModalButton {...props} />
      <PaginatedList
        indexHook={useCompetencySkills}
        indexHookArguments={[uuid]}
        originalFilters={baseCompetencySkillFilters}
        listName="competencySkills"
        list={({ data, loading }) => {
          const skills = data || [];

          return (
            <div className="col-12">
              <SkillTable skills={skills} loading={loading} {...props} />
            </div>
          );
        }}
      />
    </>
  );
};

export default List;
