import dayjs from "dayjs";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { AdministrationLevels } from "../user/userTypes";
import formatAddress from "../utils/formatAddress";
import HeaderFooter from "../utils/HeaderFooter";
import InvoiceStatusBadge from "./InvoiceStatusBadge";
import MangeInvoiceLessJobs from "./MangeInvoiceLessJobs";
import MoveJobButton from "./MoveJobButton";
import getStatus from "./status";
import generateIcs from "../utils/generateIcs";

const propTypes = {
  job: [],
};

const JobHeader = ({ job = [], setJob }) => {
  const { user } = useAuth();
  const location = useLocation();

  const isBasic =
    user.position_administration_level == AdministrationLevels.Basic &&
    !user.is_admin;
  const isPositionAdmin = user.is_position_admin;

  const userEmails = job.allUsers
    .filter((filteredUser) => filteredUser && user.uuid !== filteredUser.uuid)
    .map((user) => user.email);

  return (
    <div className="card card-profile mg-b-20 shadow-sm rounded-lg">
      <div className="card-body">
        <div className="flex-grow-1">
          <div className="media-body">
            <h3 className="card-profile-name">{job.full_name}</h3>
            {job.client && (
              <p className="tx-inverse mb-0">
                Client: <Link to={job.client.link}>{job.client.name}</Link>
              </p>
            )}
            <div className="d-flex items-center">
              <p className="tx-inverse mb-0">
                Project:{" "}
                <Link to={`/projects/${job.project.uuid}/jobs/list`}>
                  {job.project.full_name}
                </Link>
              </p>
            </div>
            {job.tender && (
              <div className="d-flex items-center">
                <p className="tx-inverse mb-0">
                  Tender: <Link to={job.tender.link}>{job.tender.name}</Link>
                </p>
              </div>
            )}
            {formatAddress(job.project) !== "Not Provided" && (
              <p className="tx-inverse mb-0">
                Address:{" "}
                {job.client ? (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                      formatAddress(job.project),
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatAddress(job.project)}
                  </a>
                ) : (
                  "Internal Project"
                )}
              </p>
            )}
            <div className="mb-2" />
            {job.invoices.length > 0 && isPositionAdmin && (
              <p className="mg-b-0 tx-inverse">
                Invoice Number{job.invoices.length > 1 ? "s" : ""}:{" "}
                {job.invoices.map((invoice, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Link to={`/invoices/${invoice.uuid}/deliverables`}>
                        {invoice.name}
                      </Link>
                      {job.invoices.length !== index + 1 && <>, </>}
                    </React.Fragment>
                  );
                })}
              </p>
            )}
            {job.docket && (
              <p className="mg-b-0 tx-inverse">
                Docket Number:{" "}
                <Link to={`/dockets/${job.docket.uuid}/details`}>
                  {job.docket.name}
                </Link>
              </p>
            )}
            {job.expected_finish_date && (
              <p className="mg-b-0 tx-inverse">
                Expected Finish Date:{" "}
                {dayjs(job.expected_finish_date).format("DD/MM/YYYY")}
              </p>
            )}
            <div className="d-flex ">
              <p className="mg-b-0 mg-t-10 space-x-2">
                {getStatus(job.status)}
                <InvoiceStatusBadge status={job.invoice_status} />
                {(job.critical_path === 1 || job.critical_path === true) && (
                  <Badge className="rounded-pill shadow" color="danger">
                    Critical Path
                  </Badge>
                )}
                {job.is_overdue && (
                  <Badge className="mg-l-5 rounded-pill shadow" color="danger">
                    Overdue
                  </Badge>
                )}
              </p>
              <div className="d-flex ms-auto">
                <Button
                  color="link"
                  onClick={() =>
                    generateIcs(`${job.name}.ics`, {
                      description: job.description,
                      dtStart: new Date(
                        `${job.scheduled_start_date} ${
                          job.scheduled_start_time ?? ""
                        }`,
                      ),
                      dtEnd: new Date(
                        `${job.scheduled_finish_date} ${
                          job.scheduled_finish_time ?? ""
                        }`,
                      ),
                      summary: job.name,
                      location: job.project.formatted_address,
                      url: `${window.location.origin}${location.pathname}`,
                    })
                  }
                >
                  Download Calendar Event
                </Button>
                {userEmails.length > 0 && (
                  <form
                    method="post"
                    action={`mailto:${userEmails.join(";")}?subject=${
                      job.project.full_name
                    }, ${job.full_name}`}
                    encType="text/plain"
                  >
                    <button type="submit" className="btn btn-link px-0">
                      Email Users
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <HeaderFooter
        extraButtons={
          <>
            <MoveJobButton job={job} setJob={setJob} />
            {isBasic || job.organisation.id != user.active_organisation.id ? null : (
              <MangeInvoiceLessJobs job={job} setJob={setJob} />
            )}
          </>
        }
      />
    </div>
  );
};

export default JobHeader;
