import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Fields from "./Fields";
import { reduxForm, submit, InjectedFormProps } from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import FormErrorAlert from "../form/FormErrorAlert";
import InformationAlert from "../utils/InformationAlert";

interface FormModalProps {
  toggle: () => void;
  modal: boolean;
  dispatch?: (action: any) => void;
  showWarning?: boolean;
  error?: string;
}

const FormModal: React.FC<
  FormModalProps & InjectedFormProps<{}, FormModalProps>
> = (props) => {
  const { toggle, modal, handleSubmit, dispatch, showWarning = false } = props;

  return (
    <Modal isOpen={modal} toggle={toggle} className="wd-md-1000 mx-wd-800 w-95">
      <ModalHeader toggle={toggle}>Add new Required Competency</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <FormErrorAlert error={props.error} />
          </div>
          {showWarning && (
            <div className="my-3">
              <InformationAlert
                type="info"
                title="New Competency Assignment"
                body="Newly added required competencies will be automatically assigned to all users."
              />
            </div>
          )}
          <Fields />
        </form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          {...props}
          onClick={() => dispatch?.(submit("RequiredCompetency"))}
        />
      </ModalFooter>
    </Modal>
  );
};

const form = reduxForm<{}, FormModalProps>({ form: "RequiredCompetency" });

export default form(FormModal);
