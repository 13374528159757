import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import SpreadPrice from "../utils/SpreadPrice";
import StatusBadge from "./StatusBadge";
import TextButton from "../utils/TextButton";
import { useMemo } from "react";
import { BiCopy } from "react-icons/bi";
import useApi from "../api/useApi";
import isSubmitting from "../utils/submitting";
import { UncontrolledTooltip } from "reactstrap";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import errorSwal from "../utils/errorSwal";

const formatPurchaseDate = (purchase) => {
  return purchase.date
    ? `${dayjs(purchase.date).format("DD/MM/YYYY")}`
    : "None given.";
};

const ResourcePurchaseList = (props) => {
  const {
    purchases,
    setSelectedPurchase,
    toggleModal,
    project,
    extraColumns = [],
    hideActions = false,
    hideProject = true,
    hideSupplier = false,
  } = props;
  const { takeAction, loading } = useApi();

  const columns = useMemo(() => {
    let columns = [
      {
        accessorKey: "name",
        header: "Purchase Number",
        cell: (info) => (
          <Link to={info.row.original.link}>{info.getValue()}</Link>
        ),
      },
      {
        accessorKey: "description",
        header: "Description",
        cell: (info) => {
          const description = info.getValue();
          if (!description) {
            return "-";
          }
          return description;
        },
      },
      {
        accessorKey: "project",
        header: "Project",
        cell: (info) => {
          const project = info.getValue();

          if (!project) {
            return "-";
          }

          return (
            <Link to={`/projects/${project.uuid}/purchases`}>
              <p className="mb-0 text-dark">{project.name}</p>
              <p className="mb-0 tx-12 text-muted">{project.number}</p>
            </Link>
          );
        },
      },
      {
        accessorKey: "supplier",
        header: "Supplier",
        cell: (info) => {
          const supplier = info.getValue();
          if (!supplier.name) {
            return "-";
          }

          if (!supplier.uuid) {
            return supplier.name;
          }

          return (
            <Link to={`/suppliers/${supplier.uuid}/details`}>
              {supplier.name}
            </Link>
          );
        },
      },
      {
        accessorKey: "date",
        header: "Purchase Date",
        cell: (info) => formatPurchaseDate(info.row.original),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) => <StatusBadge purchase={info.row.original} />,
      },
      {
        accessorKey: "realised_total_cost",
        header: "Purchase Value",
        cell: (info) => (
          <SpreadPrice price={info.row.original.realised_total_cost} />
        ),
      },
      {
        accessorKey: "invoice",
        header: "Invoice",
        cell: (info) => info.getValue()?.number ?? "-",
      },
    ];

    if (!hideActions) {
      columns.push({
        accessorKey: "actions",
        header: "Actions",
        cell: (info) => {
          return (
            <>
              {!info.row.original?.supplier?.uuid && (
                <TextButton
                  className="text-secondary"
                  onClick={() => {
                    toggleModal();
                    takeAction(
                      "show",
                      `credit-card-receipts/${info.row.original.uuid}`,
                    ).then(({ data }) => {
                      setSelectedPurchase({
                        ...data.data,
                        project_id: project.id,
                      });
                      toggleModal("credit-card-receipts");
                    });
                  }}
                >
                  {isSubmitting(loading, <BiCopy />, "")}
                </TextButton>
              )}

              {info.row.original?.paid_at && (
                <>
                  <UncontrolledTooltip
                    target={`remittance-notice-${info.row.original.uuid}`}
                  >
                    Download Remittance Notice
                  </UncontrolledTooltip>
                  <TextButton
                    className="text-secondary"
                    id={`remittance-notice-${info.row.original.uuid}`}
                    onClick={() => {
                      if (loading) {
                        return;
                      }
                      takeAction(
                        "store",
                        `remittance-notice/${info.row.original.uuid}`,
                      )
                        .then(({ data }) => {
                          window.location.href = data.data;
                        })
                        .catch(errorSwal);
                    }}
                  >
                    {isSubmitting(
                      loading,
                      <LiaFileInvoiceDollarSolid className="tx-20 tx-success" />,
                      "",
                    )}
                  </TextButton>
                </>
              )}
            </>
          );
        },
      });
    }

    if (hideProject) {
      columns = columns.filter((column) => column.accessorKey !== "project");
    }

    if (hideSupplier) {
      columns = columns.filter((column) => column.accessorKey !== "supplier");
    }

    return [...columns, ...extraColumns];
  }, [purchases, extraColumns]);

  if (purchases.length < 1) {
    return null;
  }

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <p className="tx-inverse mb-0">All prices exclude GST.</p>
      </div>
      <ReactTable disableSearch columns={columns} data={purchases} />
    </>
  );
};

export default ResourcePurchaseList;
