import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "../../context/auth-context";
import { ReactComponent as Building } from "../../svgs/building.svg";
import axios from "../api/api";
import { primary } from "../utils/Colours";
import errorSwal from "../utils/errorSwal";

const OrganisationListInstance = ({ org, index }) => {
  const [submitting, setSubmitting] = useState(false);
  const queryData = useQueryClient();
  const history = useHistory();

  const { setUser, user } = useAuth();

  const selectOrganisation = () => {
    setSubmitting(true);

    return axios
      .post(`users/organisations/active`, {
        id: org.id,
      })
      .then(({ data }) => {
        toast.success(`Organisation changed to ${org.display_name}`);
        setUser(data.data);
        localStorage.setItem("recent_organisation", data.data.active_organisation.uuid);

        queryData.resetQueries();
        if (!import.meta.env.DEV) {
          Sentry.setContext("organisation", {
            name: data.data.active_organisation.display_name,
          });
        }

        history.push("/home");
      })
      .catch((error) => {
        errorSwal(error);
        setSubmitting(false);
      });
  };

  const isCurrent = user.active_organisation.id === org.id;

  if (submitting) {
    return (
      <div className="list-group-item pd-y-20 d-flex align-items-center tx-inverse bg-gray-200">
        <div className="d-flex align-items-center">
          <Building width="100" height="" className="org-building" />
          <ClipLoader color={primary} size={40} /> Changing Organisation...
        </div>
      </div>
    );
  }

  return (
    <button
      tabIndex={(index + 1).toString()}
      type="button"
      disabled={isCurrent}
      className={`list-group-item pd-y-20 tx-inverse border organisation-select shadow-sm rounded-lg
      ${isCurrent ? "bg-gray-200" : " pointer-hover link-hover"}
      `}
      onClick={selectOrganisation}
    >
      <div className="d-flex align-items-center">
        <Building width="100" height="" className="org-building" />
        <div className="text-start">
          <h6 className="mb-0">{org.display_name}</h6>
          {isCurrent && <small>Logged in</small>}
        </div>
      </div>
    </button>
  );
};

export default OrganisationListInstance;
