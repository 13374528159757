import { Configure, InstantSearch, useInfiniteHits } from "react-instantsearch";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { useAuth } from "../../context/auth-context";
import BossSearchBox from "../utils/algolia/BossSearchBox";
import { Link } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import { Button } from "reactstrap";
import { useEffect, useMemo, useRef, useState } from "react";
import { Equipment } from "./equipmentTypes";
import { EquipmentType, CalibrationUnit } from "../../enums/Model";
import {
  DownloadData,
  EquipmentFooter,
  EquipmentHeader,
  EquipmentTable,
  info,
} from "./EquipmentList";
import PaginatedCard from "../pagination/PaginatedCard";
import EquipmentFilterModal from "./EquipmentFilterModal";
import dayjs from "dayjs";

interface EquipmentIndex extends Equipment {
  custom_values?: Array<{
    field_label: string;
    value: string;
    field_type: number;
  }>;
}

interface EquipmentFilters {
  withdrawn?: number[];
  next_calibration_due_date?: string;
  recurring_payment_next_date?: string;
  branch_id?: string[];
  type?: string[];
  custom_values?: {
    field_label?: string[];
    value?: string[];
  };
}

const NewEquipmentList = () => {
  const { user } = useAuth();
  const [filters, setFilters] = useState<EquipmentFilters>({});

  const searchClient = useMemo(() => {
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: user?.search_key ?? "",
        nodes: [
          {
            host: "search.thebossapp.com.au",
            port: 443,
            protocol: "https",
          },
        ],
      },
      additionalSearchParameters: {
        query_by:
          "name,make,model,serial_number,call_sign,sub_location,type.type,branch.name,group.name,project.name,custom_values.field_label,custom_values.value,number,padded_number",
        per_page: 18,
        /**@ts-ignore */
        num_facet_candidates: -1,
      },
    });

    return typesenseInstantsearchAdapter.searchClient;
  }, [user?.search_key]);

  const [showTable, setShowTable] = useState(false);

  const getFilterString = () => {
    const filterParts = [];

    if (filters.withdrawn?.length) {
      filterParts.push(`withdrawn:=[${filters.withdrawn.join(",")}]`);
    }

    if (filters.next_calibration_due_date) {
      filterParts.push(filters.next_calibration_due_date);
    }

    if (filters.recurring_payment_next_date) {
      filterParts.push(filters.recurring_payment_next_date);
    }

    return filterParts.join(" && ");
  };

  return (
    <>
      <HeaderPage
        titlePage="Equipment"
        pageDescription={
          <>
            Track individual pieces of equipment in your organization. You can
            add new equipment, view existing equipment, and manage their
            calibrations and assignments.
          </>
        }
        relatedLinks={[
          {
            name: "Equipment Types",
            link: "/equipment-types",
            model: EquipmentType,
          },
          {
            name: "Calibrations",
            link: "/calibration-units",
            model: CalibrationUnit,
          },
        ]}
        crumbs={[{ link: "equipment", name: "Equipment", active: true }]}
      />
      <div className="mb-3 d-flex space-x-3">
        {user?.hasAccessTo("App\\Models\\Equipment", "create") && (
          <Link
            to="/equipment/add"
            className="btn btn-outline-primary p-1 px-2"
          >
            Add new Equipment
          </Link>
        )}
        {user?.hasAccessTo("App\\Models\\Equipment", "create") && (
          <Link
            to="/equipment/import"
            className="btn btn-outline-primary p-1 px-2"
          >
            Import Equipment
          </Link>
        )}
        {user?.is_admin ? (
          <Link
            to="/equipment/custom-fields"
            className="btn btn-outline-primary p-1 px-2"
          >
            Equipment Custom Fields
          </Link>
        ) : null}
        <DownloadData />
      </div>

      <InstantSearch
        searchClient={searchClient}
        indexName={`${import.meta.env.VITE_SCOUT_PREFIX ?? ""}equipment`}
      >
        <div className="d-flex space-x-3 align-items-center">
          <BossSearchBox />
          <Configure filters={getFilterString()} />
          <EquipmentFilterModal setFilters={setFilters} filters={filters} />
          <Button
            outline
            size="sm"
            color="primary"
            className="ms-3"
            onClick={() => setShowTable(!showTable)}
          >
            {showTable ? "Show List" : "Show Table"}
          </Button>
        </div>
        <div className="row">
          <Hits showTable={showTable} />
        </div>
      </InstantSearch>
    </>
  );
};

const Hits = ({ showTable }: { showTable: boolean }) => {
  const { items, isLastPage, showMore } = useInfiniteHits<EquipmentIndex>();
  const sentinelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!sentinelRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isLastPage) {
          showMore();
        }
      });
    });

    observer.observe(sentinelRef.current);

    return () => {
      observer.disconnect();
    };
  }, [isLastPage, showMore]);

  return (
    <div className="mt-3">
      {showTable ? (
        <EquipmentTable equipment={items} />
      ) : (
        <div className="row row-sm">
          {items?.map((equipment) => {
            const updatedEquipment = {
              ...equipment,
              is_in_calibration:
                (equipment?.next_calibration_due_date
                  ? equipment?.next_calibration_due_date > dayjs().unix()
                  : false) || !equipment?.has_calibration,
            };

            return (
              <PaginatedCard
                key={equipment.id} // Add a key prop for React lists
                header={<EquipmentHeader equipment={updatedEquipment} />}
                info={info(updatedEquipment)}
                bottomContent={<EquipmentFooter equipment={updatedEquipment} />}
              />
            );
          })}
        </div>
      )}
      <div ref={sentinelRef} />
    </div>
  );
};

export default NewEquipmentList;
