import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { OnboardingStep } from "./onboardingStepTypes";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import required from "../utils/required";
import FormHeader from "../utils/FormHeader";
import CustomFields from "../customFields/CustomFields";
import { BiTrash } from "react-icons/bi";
import TextButton from "../utils/TextButton";
import { GrNext, GrPrevious } from "react-icons/gr";
import { UncontrolledTooltip } from "reactstrap";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import FormErrorAlert from "../form/FormErrorAlert";

export interface OnboardingStepFormProps {
  steps: OnboardingStep[];
  setSteps: (steps: OnboardingStep[]) => void;
  setSelectedStep: (step: OnboardingStep | undefined) => void;
}

const OnboardingStepForm = (
  props: InjectedFormProps<OnboardingStep, OnboardingStepFormProps> &
    OnboardingStepFormProps,
) => {
  const { handleSubmit, steps, initialValues, setSteps, setSelectedStep } =
    props;

  const { takeAction }: IUseApi = useApi();

  const handleMoveStep = (direction: "backward" | "forward") => {
    const currentIndex = steps.findIndex(
      (step) => step.uuid === initialValues?.uuid,
    );

    const currentSteps = steps;

    // Calculate new index based on direction
    const newIndex =
      direction === "backward" ? currentIndex - 1 : currentIndex + 1;

    // Check if move is possible
    if (newIndex < 0 || newIndex >= steps.length) return;

    // Create new array copy and swap positions
    const newSteps = [...steps];
    [newSteps[currentIndex], newSteps[newIndex]] = [
      newSteps[newIndex],
      newSteps[currentIndex],
    ];

    // Update step numbers and state
    const renumberedSteps = newSteps.map((step, i) => ({
      ...step,
      step_number: i + 1,
    }));

    setSteps(renumberedSteps);

    setSelectedStep(renumberedSteps[newIndex]);

    takeAction("update", "onboarding-steps", {
      steps: renumberedSteps,
    }).catch((err) => {
      errorSwal(err);
      setSteps(currentSteps);
      setSelectedStep(currentSteps[currentIndex]);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-12">
          <div className="d-flex items-center mt-3">
            <label className="section-title mt-0 mb-0">
              Step {props.initialValues?.step_number} -{" "}
              {props.initialValues?.name}
            </label>
            <div className="ms-auto me-3 d-flex space-x-3">
              <TextButton
                id="move-backward"
                disabled={props.initialValues?.step_number === 1}
                className="text-secondary"
                onClick={() => {
                  handleMoveStep("backward");
                }}
              >
                <GrPrevious />
              </TextButton>
              <TextButton
                id="move-forward"
                disabled={props.initialValues?.step_number === steps.length}
                className="text-secondary"
                onClick={() => {
                  handleMoveStep("forward");
                }}
              >
                <GrNext />
              </TextButton>
              {props.initialValues?.step_number !== 1 && (
                <UncontrolledTooltip target="move-backward">
                  Move to previous step
                </UncontrolledTooltip>
              )}
              {props.initialValues?.step_number !== steps.length && (
                <UncontrolledTooltip target="move-forward">
                  Move to next step
                </UncontrolledTooltip>
              )}
              <UncontrolledTooltip target="move-forward">
                Move to next step
              </UncontrolledTooltip>
            </div>
            <TextButton
              onClick={() => {
                deleteSwal(initialValues.name ?? "Step")
                  .then(() =>
                    takeAction(
                      "destroy",
                      `onboarding-steps/${initialValues.uuid}`,
                    ),
                  )
                  .then(() => {
                    toast.success(`${initialValues.name} deleted successfully`);

                    const newSteps = steps
                      .filter((step) => step.uuid !== initialValues.uuid)
                      .map((step, i) => ({
                        ...step,
                        step_number: i + 1,
                      }));

                    setSteps(newSteps);

                    setSelectedStep(
                      newSteps.length > 0 ? newSteps[0] : undefined,
                    );
                  })
                  .catch(errorSwal);
              }}
            >
              <BiTrash className="tx-18 text-danger" />
            </TextButton>
          </div>
          <hr className="w-100" />
        </div>

        <div className="form-group col-12">
          <Field
            name="name"
            component={RenderField}
            validate={required}
            required
            label="Name"
          />
        </div>
        <div className="form-group col-12">
          <Field
            name="description"
            component={RenderField}
            label="Description"
            textarea
          />
        </div>
        <FormHeader>Onboarding Information</FormHeader>
        <CustomFields {...props} />
        <div className="form-group col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<OnboardingStep, OnboardingStepFormProps>({
  form: "onboardingStepForm",
  enableReinitialize: true,
});

export default form(OnboardingStepForm);
