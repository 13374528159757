import { useState, useMemo, useCallback } from "react";
import { ModalBody, ModalHeader, Modal } from "reactstrap";
import { SelectOption } from "../utils/utilTypes";
import useModal from "../hooks/useModal";
import { useRefinementList } from "react-instantsearch";
import { MultiValue } from "react-select";
import TextButton from "../utils/TextButton";
import { FaFilter } from "react-icons/fa";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { EquipmentInvoiceDue, EquipmentStatus } from "./equipmentTypes";
import dayjs from "dayjs";

interface EquipmentFilters {
  withdrawn?: number[];
  next_calibration_due_date?: string;
  recurring_payment_next_date?: string;
  branch_id?: string[];
  type?: string[];
  custom_values?: {
    field_label?: string[];
    value?: string[];
  };
}

const EquipmentFilterModal = ({
  setFilters,
  filters,
}: {
  setFilters: (filters: EquipmentFilters) => void;
  filters: EquipmentFilters;
}) => {
  const [selectedBranches, setSelectedBranches] = useState<SelectOption[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<SelectOption[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<SelectOption[]>([]);
  const [selectedCustomLabels, setSelectedCustomLabels] = useState<
    SelectOption[]
  >([]);
  const [selectedCustomValues, setSelectedCustomValues] = useState<
    SelectOption[]
  >([]);
  const [selectedCalibrationStatus, setSelectedCalibrationStatus] =
    useState<SelectOption | null>(null);

  const [selectedInvoiceDue, setSelectedInvoiceDue] =
    useState<SelectOption | null>(null);

  const { toggle, modal } = useModal();

  const { items: branches, refine: branchRefine } = useRefinementList({
    attribute: "branch.name",
    operator: "or",
  });

  const { items: types, refine: typeRefine } = useRefinementList({
    attribute: "type.type",
    operator: "or",
  });

  const { items: statuses, refine: statusRefine } = useRefinementList({
    attribute: "withdrawn",
    operator: "or",
  });

  const { items: calibrationStatuses, refine: calibrationStatusRefine } =
    useRefinementList({
      attribute: "next_calibration_due_date",
      operator: "or",
    });

  const { items: customLabels, refine: customLabelRefine } = useRefinementList({
    attribute: "custom_values.field_label",
    operator: "or",
  });

  const { items: customValues, refine: customValueRefine } = useRefinementList({
    attribute: "custom_values.value",
    operator: "or",
  });

  const getStatusLabel = useCallback((value: string) => {
    const status = Number(value);
    return EquipmentStatus[status] || value;
  }, []);

  const getCalibrationStatusLabel = useCallback((value: string) => {
    if (!value) return "In Calibration";

    const now = dayjs().unix();

    return Number(value) > now ? "In Calibration" : "Out of Calibration";
  }, []);

  // Group custom values by their labels
  const customValuesByLabel = useMemo(() => {
    const grouped = new Map<string, Set<string>>();

    // Get all equipment items that have the selected labels
    const selectedLabelSet = new Set(
      selectedCustomLabels.map((label) => label.value.toString()),
    );

    // For each selected label, find all values that belong to it
    selectedLabelSet.forEach((label) => {
      grouped.set(label, new Set());
    });

    // For each value, check if it belongs to any of our selected labels
    // We can determine this by checking if both the label and value are refined together
    customValues.forEach((valueItem) => {
      customLabels.forEach((labelItem) => {
        const labelValue = labelItem.value.toString();
        if (selectedLabelSet.has(labelValue)) {
          // Check if this value and label appear together in the data
          // If they do, both will be refined together
          if (labelItem.isRefined && valueItem.isRefined) {
            grouped.get(labelValue)?.add(valueItem.value.toString());
          }
        }
      });
    });

    return grouped;
  }, [customValues, customLabels, selectedCustomLabels]);

  // Filter custom value options based on selected labels
  const filteredCustomValueOptions = useMemo(() => {
    if (selectedCustomLabels.length === 0) {
      return [];
    }

    // Get all values from customValues that are available
    const availableValues = new Set<string>();

    customValues.forEach((item) => {
      availableValues.add(item.value.toString());
    });

    // Convert to options format
    return Array.from(availableValues).map((value) => ({
      value,
      label: value,
    }));
  }, [customValues, selectedCustomLabels]);

  const handleRefinement = useCallback(
    (
      field: string,
      items: Array<{ value: string; isRefined: boolean }>,
      refineFn: (value: string) => void,
      selectedOptions: SelectOption[] | null,
    ) => {
      const values = selectedOptions
        ? selectedOptions.map((option) => option.value.toString())
        : [];

      items.forEach((item) => {
        if (item.isRefined) {
          refineFn(item.value);
        }
      });

      // Apply new refinements
      values.forEach((value) => {
        refineFn(value);
      });

      // Update filters based on field type
      if (
        field === "custom_values.field_label" ||
        field === "custom_values.value"
      ) {
        setFilters({
          ...filters,
          custom_values: {
            ...filters.custom_values,
            [field.split(".")[1]]: values,
          },
        });

        // When labels change, clear custom value selection and refinements
        if (field === "custom_values.field_label") {
          setSelectedCustomValues([]);
          // Clear all custom value refinements
          customValues.forEach((item) => {
            if (item.isRefined) {
              customValueRefine(item.value);
            }
          });
          // Update filters to remove custom values when labels change
          setFilters({
            ...filters,
            custom_values: {
              ...filters.custom_values,
              field_label: values,
              value: [], // Clear the values when labels change
            },
          });
        }
      } else {
        setFilters({
          ...filters,
          [field]: values.map((v) => (field === "withdrawn" ? Number(v) : v)),
        });
      }
    },
    [filters, setFilters, customValues, customValueRefine],
  );

  const handleCalibrationStatusChange = useCallback(
    (selectedOption: SelectOption | null) => {
      setSelectedCalibrationStatus(selectedOption);

      if (!selectedOption) {
        const { next_calibration_due_date, ...restFilters } = filters;
        setFilters(restFilters);

        return;
      }

      const now = dayjs().unix();

      const filter = selectedOption.value
        ? `next_calibration_due_date:>${now} || has_calibration:=false`
        : `next_calibration_due_date:<${now} && withdrawn:!=${EquipmentStatus.OutOfService}`;

      setFilters({
        ...filters,
        next_calibration_due_date: filter,
      });
    },
    [calibrationStatuses, calibrationStatusRefine, filters, setFilters],
  );

  // Memoize options
  const branchOptions = useMemo(
    () => branches.map((item) => ({ value: item.value, label: item.label })),
    [branches],
  );

  const typeOptions = useMemo(
    () => types.map((item) => ({ value: item.value, label: item.label })),
    [types],
  );

  const statusOptions = useMemo(
    () =>
      statuses.map((item) => ({
        value: item.value,
        label: getStatusLabel(item.value),
      })),
    [statuses, getStatusLabel],
  );

  const calibrationStatusOptions = useMemo(
    () =>
      calibrationStatuses.map((item) => ({
        value: item.value,
        label: getCalibrationStatusLabel(item.value),
      })),
    [calibrationStatuses, getCalibrationStatusLabel],
  );

  const customLabelOptions = useMemo(
    () =>
      customLabels.map((item) => ({ value: item.value, label: item.value })),
    [customLabels],
  );

  const customValueOptions = useMemo(
    () =>
      customValues.map((item) => ({ value: item.value, label: item.value })),
    [customValues],
  );

  // Helper function to filter options based on search input
  const filterOptions = useCallback(
    (options: SelectOption[], inputValue: string) => {
      const lowerInput = inputValue.toLowerCase();
      // Create a Set to track unique values
      const uniqueValues = new Set<string>();
      return options.filter((option) => {
        const label = String(option.label || "").toLowerCase();
        // Only include the option if we haven't seen this value before
        if (!uniqueValues.has(label)) {
          uniqueValues.add(label);
          return label.includes(lowerInput);
        }
        return false;
      });
    },
    [],
  );

  const handleInvoiceDueChange = useCallback(
    (selectedOption: SelectOption | null) => {
      setSelectedInvoiceDue(selectedOption);
      if (!selectedOption) {
        const { recurring_payment_next_date, ...restFilters } = filters;
        setFilters(restFilters);
        return;
      }

      const now = dayjs();

      switch (selectedOption.value) {
        case EquipmentInvoiceDue.NextWeek: // Next week
          const nextWeekStart = now.add(1, "week").startOf("week");
          const nextWeekEnd = now.add(1, "week").endOf("week");
          setFilters({
            ...filters,
            recurring_payment_next_date: `recurring_payment_next_date:>${nextWeekStart.unix()} && recurring_payment_next_date:<${nextWeekEnd.unix()}`,
          });
          break;

        case EquipmentInvoiceDue.ThisWeek: // This week
          const thisWeekStart = now.startOf("week");
          const thisWeekEnd = now.endOf("week");
          setFilters({
            ...filters,
            recurring_payment_next_date: `recurring_payment_next_date:>${thisWeekStart.unix()} && recurring_payment_next_date:<${thisWeekEnd.unix()}`,
          });
          break;

        case EquipmentInvoiceDue.ThisMonth: // This month
          const monthStart = now.startOf("month");
          const monthEnd = now.endOf("month");
          setFilters({
            ...filters,
            recurring_payment_next_date: `recurring_payment_next_date:>${monthStart.unix()} && recurring_payment_next_date:<${monthEnd.unix()}`,
          });
          break;

        default:
          break;
      }
    },
    [filters, setFilters],
  );

  return (
    <>
      <TextButton onClick={toggle}>
        <FaFilter className="tx-16" />
      </TextButton>
      <Modal
        backdrop="static"
        className="wd-md-600 mx-wd-800"
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Filter Equipment</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-6 form-group">
              <label className="form-control-label tx-inverse tx-semibold">
                Branch
              </label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                value={selectedBranches}
                loadOptions={(
                  inputValue: string,
                  callback: (options: SelectOption[]) => void,
                ) => callback(filterOptions(branchOptions, inputValue))}
                onChange={(selectedOptions: MultiValue<SelectOption>) => {
                  setSelectedBranches(selectedOptions as SelectOption[]);
                  handleRefinement(
                    "branch.id",
                    branches,
                    branchRefine,
                    selectedOptions as SelectOption[],
                  );
                }}
              />
            </div>
            <div className="col-lg-6 form-group">
              <label className="form-control-label tx-inverse tx-semibold">
                Type
              </label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                value={selectedTypes}
                loadOptions={(
                  inputValue: string,
                  callback: (options: SelectOption[]) => void,
                ) => callback(filterOptions(typeOptions, inputValue))}
                onChange={(selectedOptions: MultiValue<SelectOption>) => {
                  setSelectedTypes(selectedOptions as SelectOption[]);
                  handleRefinement(
                    "type.type",
                    types,
                    typeRefine,
                    selectedOptions as SelectOption[],
                  );
                }}
              />
            </div>
            <div className="col-lg-6 form-group">
              <label className="form-control-label tx-inverse tx-semibold">
                Status
              </label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                value={selectedStatuses}
                loadOptions={(
                  inputValue: string,
                  callback: (options: SelectOption[]) => void,
                ) => callback(filterOptions(statusOptions, inputValue))}
                onChange={(selectedOptions: MultiValue<SelectOption>) => {
                  setSelectedStatuses(selectedOptions as SelectOption[]);
                  handleRefinement(
                    "withdrawn",
                    statuses,
                    statusRefine,
                    selectedOptions as SelectOption[],
                  );
                }}
              />
            </div>
            <div className="col-lg-6 form-group">
              <label className="form-control-label tx-inverse tx-semibold">
                Calibration Status
              </label>
              <Select
                value={selectedCalibrationStatus}
                options={[
                  {
                    label: "In Calibration",
                    value: 1,
                  },
                  {
                    label: "Out of Calibration",
                    value: 0,
                  },
                ]}
                onChange={handleCalibrationStatusChange}
                isClearable
              />
            </div>
            <div className="col-lg-6 form-group">
              <label className="form-control-label tx-inverse tx-semibold">
                Custom Field
              </label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                value={selectedCustomLabels}
                loadOptions={(
                  inputValue: string,
                  callback: (options: SelectOption[]) => void,
                ) => callback(filterOptions(customLabelOptions, inputValue))}
                onChange={(selectedOptions: MultiValue<SelectOption>) => {
                  setSelectedCustomLabels(selectedOptions as SelectOption[]);
                  handleRefinement(
                    "custom_values.field_label",
                    customLabels,
                    customLabelRefine,
                    selectedOptions as SelectOption[],
                  );
                }}
              />
            </div>
            <div className="col-lg-6 form-group">
              <label className="form-control-label tx-inverse tx-semibold">
                Custom Value
              </label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                value={selectedCustomValues}
                loadOptions={(
                  inputValue: string,
                  callback: (options: SelectOption[]) => void,
                ) =>
                  callback(
                    filterOptions(filteredCustomValueOptions, inputValue),
                  )
                }
                onChange={(selectedOptions: MultiValue<SelectOption>) => {
                  setSelectedCustomValues(selectedOptions as SelectOption[]);
                  handleRefinement(
                    "custom_values.value",
                    customValues,
                    customValueRefine,
                    selectedOptions as SelectOption[],
                  );
                }}
                isDisabled={selectedCustomLabels.length === 0}
              />
            </div>
            <div className="col-lg-6 form-group">
              <label className="form-control-label tx-inverse tx-semibold">
                Invoice Due
              </label>
              <Select
                value={selectedInvoiceDue}
                options={[
                  {
                    label: "This month",
                    value: EquipmentInvoiceDue.ThisMonth,
                  },
                  {
                    label: "This week",
                    value: EquipmentInvoiceDue.ThisWeek,
                  },
                  {
                    label: "Next week",
                    value: EquipmentInvoiceDue.NextWeek,
                  },
                ]}
                onChange={handleInvoiceDueChange}
                isClearable
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EquipmentFilterModal;
