import { useInfiniteQuery } from "react-query";
import api from "../../api/api";
import { LaravelPaginatedResponse } from "../../utils/utilTypes";

interface CompetencySkill {
  uuid: string;
  name: string;
  description: string;
  skill_category_uuid: string;
  skill_type: string;
  created_at: string;
  updated_at: string;
}

interface GetCompetencySkillsParams {
  pageParam?: number;
}

const getCompetencySkills = (
  { pageParam = 1 }: GetCompetencySkillsParams,
  uuid: string,
  search = "",
): Promise<LaravelPaginatedResponse<CompetencySkill>> => {
  if (!pageParam) {
    throw new Error("Page parameter is required");
  }

  return api
    .get(
      `/skill-categories/${uuid}/competency-skills?page=${pageParam}&filter[search]=${search}&per_page=9`,
    )
    .then(({ data }) => data);
};

export const baseCompetencySkillFilters = [
  {
    label: "Type",
    name: "skill_type",
    options: [
      {
        label: "Job Type",
        value: "App\\Models\\JobType",
      },
      {
        label: "Qualification",
        value: "App\\Models\\Qualification",
      },
      {
        label: "Test Method",
        value: "App\\Models\\TestMethod",
      },
    ],
  },
];

export default function useCompetencySkills(search = "", uuid: string) {
  return useInfiniteQuery(
    ["competency-skills", { uuid, search }],
    (pageParam) => getCompetencySkills(pageParam, uuid, search),
    {
      getNextPageParam: (
        lastPage: LaravelPaginatedResponse<CompetencySkill>,
      ) => {
        if (lastPage.meta.current_page === lastPage.meta.last_page) {
          return;
        }

        return lastPage.meta.current_page + 1;
      },
    },
  );
}
