import { useEffect } from "react";
import Linkify from "react-linkify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import CommentItem from "./CommentItem";

const ListModal = (props) => {
  const { response, toggle, modal, commentableType, commentableId } = props;

  const { data: comments, setUrl, loading } = useApi(null, [], true);

  useEffect(() => {
    if (modal) {
      setUrl(
        `comments?filter[commentable_type]=${encodeURIComponent(
          commentableType,
        )}&filter[commentable_id]=${commentableId}&paginate=${
          response?.data?.meta?.total
        }`,
      );
    }
  }, [modal]);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="modal-lg wd-md-600"
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Comments</ModalHeader>

      <ModalBody>
        {loading ? (
          <CustomScaleLoader>Fetching Comments...</CustomScaleLoader>
        ) : (
          <Linkify properties={{ target: "_blank" }}>
            {comments.map((comment) => {
              return <CommentItem key={comment.uuid} comment={comment} />;
            })}
          </Linkify>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ListModal;
