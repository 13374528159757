import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import { Field, reduxForm, submit } from "redux-form";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import useSelectedDocuments from "../hooks/useSelectedDocuments";
import FileInput from "../upload/FileInput";
import RenderField from "../utils/renderField";
import CustomScaleLoader from "../utils/scaleLoader";
import SubmitButton from "../utils/SubmitButton";
import documentProps from "./documentProps";
import FileListItem from "./FileListItem";
import PreviewModal from "./PreviewModal";
import { useAuth } from "../../context/auth-context";

const VersionModal = (props) => {
  const {
    toggle,
    modal,
    document,
    handleSubmit,
    dispatch,
    form,
    percent = 0,
  } = props;

  const { user } = useAuth();

  const { takeAction, loading, response } = useApi();

  const [documents, setDocuments] = useState([]);

  const { selectedDocument, setSelectedDocument, changeDocument } =
    useSelectedDocuments(documents);

  const { modal: previewModal, toggle: togglePreview } = useModal();

  useEffect(() => {
    if (modal && !response) {
      takeAction("index", `documents/${document.uuid}/versions`).then(
        ({ data }) => {
          setDocuments(data.data);
        },
      );
    }
  }, [modal]);

  const fetching = loading && !response;

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="wd-md-1000 mx-wd-800 w-95"
      >
        <ModalHeader toggle={toggle}>{document.name} Versions</ModalHeader>
        <ModalBody>
          {user.hasAccessTo("App\\Models\\Document", "create_add_version") && (
            <form onSubmit={handleSubmit} className="mb-5">
              <div className="row">
                <div className="col-12 form-group">
                  <Field
                    component={FileInput}
                    percent={percent}
                    name="revision"
                    label="File"
                  />
                </div>
                <div className="col-12 form-group">
                  <Field
                    component={RenderField}
                    textarea
                    name="reason"
                    label="Revision Reason"
                  />
                </div>
              </div>
            </form>
          )}
          {fetching && (
            <CustomScaleLoader>Fetching versions...</CustomScaleLoader>
          )}
          {!fetching && documents.length === 0 && (
            <div>
              <img
                className="w-100"
                src="/img/empty_documents.svg"
                height="200"
                alt="No Versions Found"
              />
              <p className="fw-bold w-100 tx-inverse text-center mt-5 mb-2">
                No versions for {document.name} found.
              </p>
              <p className="w-100  text-center mb-0">
                You can upload revisions to keep track of changes.
              </p>
            </div>
          )}
          {documents.length > 0 && (
            <p className="fw-bold w-100 tx-inverse mb-2">Versions</p>
          )}
          {documents.map((d) => (
            <button
              key={d.uuid}
              type="button"
              className="btn btn-link p-0 w-100"
              onClick={() => {
                togglePreview();
                return setSelectedDocument(d);
              }}
            >
              <FileListItem document={d} id={`file-${d.uuid}`} />
              <UncontrolledTooltip target={`file-${d.uuid}`}>
                <div className="px-3 py-2">
                  <p className="mb-0 fw-bold text-white-50">Revision Reason:</p>
                  <p className="mb-0">{d.reason}</p>
                </div>
              </UncontrolledTooltip>
            </button>
          ))}
        </ModalBody>
        <ModalFooter>
          {user.hasAccessTo("App\\Models\\Document", "create_add_version") && (
            <SubmitButton onClick={() => dispatch(submit(form))} {...props} />
          )}
        </ModalFooter>
      </Modal>
      <PreviewModal
        modal={previewModal}
        toggle={togglePreview}
        document={selectedDocument}
        changeDocument={changeDocument}
      />
    </>
  );
};

VersionModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  ...documentProps,
  percent: PropTypes.number,
};

export default reduxForm()(VersionModal);
